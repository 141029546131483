export default class Application {
	#BASE_API_URL = this.#addEndSlash(this.#trimSlash(process.env.REACT_APP_API_URL_BASE));

	constructor() {
		if (!window.location.hostname.includes('localhost')) {
			this.#BASE_API_URL = window.location.protocol + "//" + window.location.hostname + "/api/";
		}
	}

	/**
	 * Удаление sid из local storage и перехода на страницу аутентификации
	 */
	static logout() {
		localStorage.removeItem("name");
		localStorage.removeItem("login");
		localStorage.removeItem("asid");
		window.location.href = "/SignIn";
	}
	
	/**
	 * Возвращает URL к API.
	 * @returns {URL} URL к API.
	 */
	getApiBaseUrl() {
		return this.#BASE_API_URL;
	}

	/**
	 * Возвращает URL к API административного модуля.
	 * @returns {URL} URL к API административного модуля.
	 */
	getAdminUrl() {
		return new URL(this.#addEndSlash(this.#trimSlash(process.env.REACT_APP_API_ADMIN)),
			this.getApiBaseUrl());
	}

	/**
	 * Возвращает URL к API модуля Destiny Herald.
	 * @returns {URL} URL к API модуля Destiny Herald.
	 */
	getDestinyHeraldUrl() {
		return new URL(this.#addEndSlash(this.#trimSlash(process.env.REACT_APP_API_DHERALD)),
			this.getApiBaseUrl());
	}

	#trimSlash(url) {
		let stringValue = url.toString();
		
		while (stringValue.length > 0 & stringValue[stringValue.length - 1] === '/') {
			stringValue = stringValue.slice(0, -1);
		}

		while (stringValue.length > 0 & stringValue[0] === '/') {
			stringValue = stringValue.slice(1);
		}

		return stringValue;
	}

	#addEndSlash(url) {
		return url.toString() + '/';
	}
}