import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import Input from '@mui/joy/Input';
import Divider from '@mui/joy/Divider';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.jsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

export default function Task_Effectivity(props) {

	//Стили
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const headers =[{id:'acFamily',name:'AC_Family'}, {id:'acConfigurationDescription',name:'AC_Configuration_Description'}, {id:'remarks',name:'Remarks'}, {id:'ampId',name:'AMP_ID'}]

	const [data, setData] = React.useState<{id:number, acFamily:string, acConfigurationDescription:string, remarks:string, ampId:number}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, acFamily:string, acConfigurationDescription:string, remarks:string, ampId:number}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({id:0, acConfigurationDescription:'', remarks:''});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [showEditor, setShowEditor] = React.useState (true);
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpTaskEffectivities?$filter=acFamily eq '${props.selectedACFamily}' and ampId eq ${props.selectedAmp.id}`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			const sortedResult =result.value.sort((a, b) => b.id - a.id);
			setData(sortedResult);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		fetchData();
	}, []);

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredData(filtered);

		//Сброс выделения строки, если она не попадает в отфильтрованные данные
		if(!filtered.some(obj => obj.id === selectedRow.id)){
			setSelectedRow({id:0})
		}

    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, acConfigurationDescription:'', remarks:''});
		setSelectedRow({id:0});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		const initialState = {id:0, acConfigurationDescription:'', remarks:''};
		return Object.keys(initialState).every(key => filters[key] === initialState[key]);
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}

	//#region Изменение ширины AddEditForm
	const [isDragging, setIsDragging] = React.useState(false);
	const [defWidth, setDefWidth] = React.useState(600)
  	const [addEditFormWidth, setAddEditFormWidth] = React.useState(defWidth);
	const [startPosition, setStartPosition] = React.useState({ x: 0});

	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({ x: e.clientX});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		const deltaX = e.clientX - startPosition.x;
		const newWidth = defWidth-deltaX;
		if (newWidth >= 10) {
			setAddEditFormWidth(newWidth )
		} else {
			return;
		}
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
		setDefWidth(addEditFormWidth)
	};
	//#endregion

	const rowRefs = React.useRef([]);

	const handleFocusRow = () => {
        const selectedRowIndex = filteredData.findIndex(row => row.id === selectedRow.id);
        if (selectedRowIndex !== -1 && rowRefs.current[selectedRowIndex]) {
            rowRefs.current[selectedRowIndex].focus();
        }
    };

	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				id='main'
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:showEditor?`auto 10px ${addEditFormWidth}px`:'100%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							whiteSpace: 'nowrap', 
							overflow: 'hidden',    
							textOverflow: 'ellipsis',
							width:`calc(100% - 9px)`,
							left:16
						}}
					>
						Aircraft Configuration Task Effectivity:
					</Typography>
					<Box
						onClick={() => setShowFilters(!showFilters)}
						sx={{
							border:'1px solid black',
							borderBottom:'none',
							height:'24px',
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							bgcolor:checkFilters()?'transparent':'#DDE7EE',
							position:'absolute',
							width: `calc(100% - 32px)`,
							top:'22px',
							left:'16px'
						}}
					>
						<ArrowDropDownIcon 
							sx={{
								fontSize: '20px',
								transition: '0.2s',
								transform: !showFilters ? 'rotate(0deg)' : 'rotate(180deg)',
							}}
						/>
					</Box>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 56px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							mx:'16px',
							mt:'46px',
							mb:'10px',
							bgcolor:'#848284'
						}}
					>
						<Table
							borderAxis="both"
							stickyHeader
							hoverRow
							sx={{
								'--TableCell-headBackground': '#d6d3ce',
								'--Table-headerUnderlineThickness': '1px',
								'--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
								'--TableCell-paddingY': '1px',
								'--TableCell-height': '29px',
								'--TableCell-paddingX': '5px',
								'--TableCell-borderColor': '#939293',
								tableLayout: 'auto', 
								width:'auto',
								borderRadius:'0px'
							}}
						>
							<thead>
								<tr>
									<th style={headerStyle}>
										<Typography
											onClick={() => handleSort('id', sort.order == 'asc' ? 'desc':'asc' )}
											endDecorator={<ArrowDropDownIcon />}
											sx={{
												'& svg': {
													color:sort.lable === 'id' ? '#636b74' : 'transparent',
													transition: '0.2s',
													transform:sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
												},
												fontWeight:filters.id != 0 ? 'bold' : 'normal'
											}}
										>
											ID
										</Typography>
									</th>
									{ headers.map((row) => (
										<th style={headerStyle}>
											<Typography
												onClick={() => handleSort(row.id, sort.order == 'asc' ? 'desc':'asc' )}
												endDecorator={<ArrowDropDownIcon />}
												sx={{
													'& svg': {
														color:sort.lable === row.id ? '#636b74' : 'transparent',
														transition: '0.2s',
														transform: sort.order === 'asc' ? 'rotate(0deg)' : 'rotate(180deg)',
													},
													fontWeight:filters[row.id] ? 'bold' : 'normal'
												}}
											>
												{row.name}
											</Typography>
										</th>
									))}
								</tr>
								{showFilters && (
									<tr>
										<th style={{padding:0}}>
											<Input
												value={filters.id}
												onChange={(event) => handleChange('id', event.target.value)}
												type="number"
												slotProps={{
													input: {
														step: 1,
														min:0
													}}}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}></th>
										<th style={{padding:0}}>
											<Input
												value={filters.acConfigurationDescription}
												onChange={(event) => handleChange('acConfigurationDescription', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}>
											<Input
												value={filters.remarks}
												onChange={(event) => handleChange('remarks', event.target.value)}
												sx={filtersStyle}
											/>
										</th>
										<th style={{padding:0}}></th>
									</tr>
								)}
							</thead>
							<tbody>
								{filteredData.length == 0 && (
									<th colSpan={999}>
										<td style={{...rowStyle, textAlign:'center'}}>No Records Where Found</td>
									</th>
								)}
								{filteredData.map((row, index) => (
									<tr
										key={row.id}
										onClick={() => handleRowSelection(row)}
										style={{ 
											backgroundColor: selectedRow.id === row.id ? '#c0c0ff': index % 2 === 0 ? '#EDF2F7' : 'white',
											color: 'black',
										}}
										ref={el => (rowRefs.current[index] = el)}
                            			tabIndex={0}
									>
										<td style={{...rowStyle, backgroundColor: selectedRow.id === row.id ? '#c0c0ff' :'#c6c3c6'}}>{row.id}</td>
										<td style={rowStyle}>{row.acFamily}</td>
                                		<td style={rowStyle}>{row.acConfigurationDescription}</td>
										<td style={rowStyle}>{row.remarks}</td>
										<td style={rowStyle}>{row.ampId}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</Sheet>
				</Box>
				<Box
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					sx={{
						cursor:'ew-resize',
						position:'relative',
					}}
				>
					<Divider 
						onMouseDown={handleMouseDown}
						onMouseMove={handleMouseMove}
						onMouseUp={handleMouseUp}
						orientation="vertical" 
						sx={{
							cursor:'ew-resize',
							position:'absolute',
							height:'100%'
						}}
					/>
				</Box>
				<Box
					id='editFormArea'
					sx={{
						display:showEditor?'flex':'none',
						position:'relative',
					}}
				>
					<AddEditeForm
						URL={props.URL}
						selectedRow={selectedRow} 
						setSelectedRow={setSelectedRow}
						reloadTable={fetchData}
						focusRow={handleFocusRow}
						setResponseStatus={setResponseStatus}
						selectedACFamily={props.selectedACFamily}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
