import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';
import AutocompleteOption from '@mui/joy/AutocompleteOption';
import AutocompleteListbox from '@mui/joy/AutocompleteListbox';

import { Popper } from '@mui/base/Popper';
import { FixedSizeList, ListChildComponentProps } from 'react-window';

import CloseIcon from '@mui/icons-material/Close';

//#region Виртуализация списка pn 
function renderRow(props: ListChildComponentProps) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: (style.top as number),
	};

	return (
		<AutocompleteOption {...dataSet[0]} style={inlineStyle}>
			{dataSet[1]}
		</AutocompleteOption>
	);
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return (
		<AutocompleteListbox
			{...props}
			{...outerProps}
			component="div"
			ref={ref}
			sx={{
				'& ul': {
					padding: 0,
					margin: 0,
					flexShrink: 0,
				},
			}}
		/>
	);
});

// Adapter for react-window
const ListboxComponent = React.forwardRef<
	HTMLDivElement,
	{
		anchorEl: any;
		open: boolean;
		modifiers: any[];
	} & React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
	const { children, anchorEl, open, modifiers, ...other } = props;
	const itemData: Array<any> = [];
	(
		children as [Array<{ children: Array<React.ReactElement<any>> | undefined }>]
	)[0].forEach((item) => {
		if (item) {
			itemData.push(item);
			itemData.push(...(item.children || []));
		}
	});

  const itemCount = itemData.length;
  const itemSize = 40;

  return (
    <Popper ref={ref} anchorEl={anchorEl} open={open} modifiers={modifiers}>
		<OuterElementContext.Provider value={other}>
			<FixedSizeList
				itemData={itemData}
				height={itemSize * 8}
				width="100%"
				outerElementType={OuterElementType}
				innerElementType="ul"
				itemSize={itemSize}
				overscanCount={5}
				itemCount={itemCount}
			>
				{renderRow}
			</FixedSizeList>
		</OuterElementContext.Provider>
    </Popper>
  );
});
//#endregion


export default function SubstitutionRegistration(props) {
	//Стили
	const inputStyle = { borderRadius: 0, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'16px' }
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'14px', alignSelf:'center' }
	const formControlStyle = { mb: 1 }
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'18px',color:'black'};
    const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};

    const [showListOfPn, setShowListOfPn] = React.useState(false)

	const handleClose = () => {
		props.setOpen(false);
	};

	let xcenter = (window.innerWidth/2)-1600;
	let ycenter = (window.innerHeight/2)-400;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

    const [pnList, setPnList] = React.useState([])
    //Запрос списка PN
	const fetchPnList = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/PartsCatalogs/Pns(pn='',description='')`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setPnList(result);
		} else {
			props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

    React.useEffect(() => {
		fetchPnList();
	}, []);

	return (
		<React.Fragment>
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 10,
					overflow:'hidden',
				}}
       		/>
				<Sheet
					variant="plain"
					sx={{
						height:'600px',
						width:'800px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:11,
						left: position.x,
						top: position.y,
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'30px 100px 467px',
							bgcolor:'#d6d3ce'
						}}
					>
						<Box
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							sx={{
								display:'flex',
								alignItems:'center',
								background:'#08246b',
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
						>
							<Typography 
								sx={{
									fontSize:'15px',
									color:'white',
                                    ml:1
								}}
							>
								Parts Substitution Registration
							</Typography>
							<IconButton 
							 	onClick={handleClose}
								sx={{
									mr:1,
									ml:'auto',
									'--Icon-color':'white',
									height:'19px',
									minHeight:'0px',
									width:'19px',
									minWidth:'0px'
								}}
							>
								<CloseIcon/>
							</IconButton>
						</Box>
						<Box>
							<Typography
								sx={{
									color:'black',
									ml:1,
									mt:1,
									fontWeight:'bold'
								}}							
							> 
								Selected Part:
							</Typography>
							<Grid container spacing={0} sx={{ flexGrow: 1 }}>
								<Grid xs={6}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>
											Selected P/N:
										</FormLabel>
										<Input
                                            //value={{ label: editedData?.pn ?? ''}}
                                            //onChange={(event, value) => handleChange('pn', value ?? '')}
                                            //onKeyDown={(event) => handleAdditionalInfo(event, 'mfrCode')}
                                            sx={{
                                                ...inputStyle,
                                                backgroundColor:'#c6ffff',
                                            }}
                                        />
									</FormControl>
								</Grid>
								<Grid xs={6}>
									<FormControl sx={formControlStyle}>
										<FormLabel sx={labelStyle}>
											Description: 
										</FormLabel>
										<Input 
											size="sm" 
											disabled
											sx={{
												...inputStyle, 
												'--variant-outlinedDisabledColor':'black',
												'--variant-outlinedDisabledBorder':'black',
												bgcolor:'#d6d3ce',
											}} 
											//value={editedOperator.name ? operators.find(item => item.name === editedOperator.name)?.icao : ''}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</Box>
						<Box
							sx={{
								display:'flex',
								flexDirection:'row',
								justifyContent:'center',
								maxHeight:'467px',
							}}
						>
							<Box
								sx={{
									height:'467px',
									maxHeight:'467px',
									width:'496px',
									px:1
								}}
							>
								<Typography
									sx={{
										fontWeight:'bold',
										color:'#000078',
										fontFamily:'Arial, sans-serif'
									}}							
								> 
									Selected Part's Substitution:
								</Typography>
								<Sheet
									className="OrderTableContainer"
									variant="outlined"
									sx={{
										minWidth: '0px',
										borderRadius: '0px',
										flexShrink: 1,
										overflow: 'auto',
										minHeight: 0,
										width:'100%',
										bgcolor:'white',
										height:'435px',
										border:'1px solid black'
									}}
								>
									
								</Sheet>
							</Box>
							<Box
								sx={{
									height:'467px',
									maxHeight:'467px',
									width:'300px'
								}}
							>
								<Typography 
									sx={{ 
										ml: 1,
										fontWeight:'bold',
										color:'#000078',
										fontFamily:'Arial, sans-serif'
									}}
								>
									Lower Part Editor:
								</Typography>
								<Tooltip title='Add'>
									<IconButton
										variant='plain'
										disabled={true}
										//onClick={handleAdd}
										sx={{
											m:0,
											'&:disabled img': {
												opacity: 0.5,
											}
										}}
									>
										<img 
											width='35px' 
											height='35px' 
											src="/images/svg/add.svg" 
											alt="" 
										/>
									</IconButton>
								</Tooltip>
								<Tooltip title='Update'>
									<IconButton 
										variant='plain'
										disabled={true}
										//onClick={handleUpdate}
										sx={{
											m:0,
											'&:disabled img': {
												opacity: 0.5,
											}
										}}
									>
										<img 
											width='35px' 
											height='35px' 
											src="/images/svg/save.svg" 
											alt="" 
										/>
									</IconButton>
								</Tooltip>
								<Tooltip title='Delete'>
									<IconButton 
										variant='plain'
										disabled={true}
										//onClick={handleDelete}
										sx={{
											m:0,
											'&:disabled img': {
												opacity: 0.5,
											}
										}}
									>
										<img 
											width='35px' 
											height='35px'  
											src="/images/svg/trashcan.svg" 
											alt="" 
										/>
									</IconButton>
								</Tooltip>
								<Grid container spacing={0} sx={{ flexGrow: 1 }}>
									<Grid xs={12} sx={{position:'relative'}}>
										<FormControl sx={formControlStyle} required>
											<FormLabel sx={labelStyle}>
												Substitution P/N:
											</FormLabel>
											<Input
												//value={editedData.code}
												sx={{...inputStyle, backgroundColor:'#c6ffff'}}
												//onChange={(event, value) => handleChange('code', value ? value.label : '')}
												//onKeyDown={handleOperatorAdditionalInfo}
											/>
										</FormControl>
                                        <Button		
                                            color='neutral'
                                            sx={{
                                                borderRadius:0,
                                                height: 20, 
                                                minHeight: 0,
                                                fontWeight:100,
                                                fontSize:14,
                                                bgcolor:'#c7c3fe',
                                                color:'black',
                                                border:'2px outset grey',
                                                position:'absolute',
                                                top:0,
                                                right:8,
                                                py:0,
                                                px:1
                                            }}
                                            onClick={()=>setShowListOfPn(!showListOfPn)}
                                        >
                                            Select
                                        </Button>
									</Grid>
                                    {showListOfPn ? (
                                        <>
                                            <Typography sx={labelStyle}>
                                                List of PN:    
                                            </Typography>
                                            <Grid xs={12}>
                                                <Box
                                                    sx={{
                                                        border:'1px solid black',
                                                        height:'264px',
                                                        mx:1,
                                                        borderRadius:'0px',
                                                        bgcolor:'white',
                                                        overflow:'auto',
                                                    }}
                                                >
                                                    <Table
                                                        aria-labelledby="tableTitle"
                                                        borderAxis="none"
                                                        stickyHeader
                                                        hoverRow
                                                        sx={{
                                                            '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                                                            '--TableCell-paddingY': '1px',
                                                            "--TableCell-height": "16px",
                                                            '--TableCell-paddingX': '5px',
                                                            tableLayout: 'auto', 
                                                            width:'auto',
                                                            borderRadius:'0px',
                                                            lineHeight:0,
                                                            backgroundColor:'#c6ffff',
                                                        }}
                                                    >
                                                        <tbody>
                                                            {/*positionPnList.map((row) => (
                                                                <tr
                                                                    key={row.pn}
                                                                    //onClick={() => setSelectedGroupForModule(row)}
                                                                    style={{ 
                                                                        //backgroundColor: selectedGroupForModule === row ? '#00cfd6' : 'white',
                                                                        //color: selectedGroupForModule === row ? 'white' : 'black',
                                                                    }}
                                                                >
                                                                    <td style={rowStyle}>{row.pn}</td>
                                                                    <td style={rowStyle}>{row.description}</td>
                                                                </tr>
                                                            ))*/}
                                                        </tbody>
                                                    </Table>
                                                </Box>
                                            </Grid>
                                            <Grid xs={6}>
                                                <FormControl sx={formControlStyle}>
                                                    <FormLabel sx={labelStyle}>
                                                        Filter PN: 
                                                    </FormLabel>
                                                    <Input 
                                                        type="number"
                                                        slotProps={{
                                                            input: {
                                                                step: 1,
                                                                min:0
                                                            }
                                                        }}
                                                        sx={{
                                                            ...inputStyle, 
                                                        }} 
                                                        //value={editedData.code ? sta.find(item => item.code === editedData.code)?.country : ''}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={6}>
                                                <FormControl sx={formControlStyle}>
                                                    <FormLabel sx={labelStyle}>
                                                        Filter Description: 
                                                    </FormLabel>
                                                    <Input 
                                                        type="number"
                                                        slotProps={{
                                                            input: {
                                                                step: 1,
                                                                min:0
                                                            }
                                                        }}
                                                        sx={{
                                                            ...inputStyle, 
                                                        }} 
                                                        //value={editedData.code ? sta.find(item => item.code === editedData.code)?.country : ''}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    ):(
                                        <>
                                            <Grid xs={12}>
                                                <FormControl sx={formControlStyle}>
                                                    <FormLabel sx={labelStyle}>
                                                        Description:
                                                    </FormLabel>
                                                    <Textarea
                                                        maxRows={7}
                                                        disabled
                                                        //value={editedData.remarks}
                                                        sx={{ 
                                                            ...inputStyle, 
                                                            height: '50px', 
                                                            '--variant-outlinedDisabledColor':'black',
                                                            '--variant-outlinedDisabledBorder':'black',
                                                            bgcolor:'#d6d3ce',
                                                        }}
                                                        //onChange={(event) => handleChange('remarks', event.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={6}>
                                                <FormControl sx={formControlStyle} required>
                                                    <FormLabel sx={labelStyle}>
                                                    	Interchangeability: 
                                                    </FormLabel>
													<Autocomplete
														options={[]}
														//value={editedData.code}
														sx={{...inputStyle, backgroundColor:'#c6ffff'}}
														//onChange={(event, value) => handleChange('code', value ? value.label : '')}
														//onKeyDown={handleOperatorAdditionalInfo}
													/>
                                                </FormControl>
                                            </Grid>
                                            <Grid xs={12}>
                                                <FormControl sx={formControlStyle}>
                                                    <FormLabel sx={labelStyle}>
                                                        Reference:
                                                    </FormLabel>
                                                    <Textarea
                                                        maxRows={7}
                                                        //value={editedData.remarks}
                                                        sx={{ ...inputStyle, height: '180px', }}
                                                        //onChange={(event) => handleChange('remarks', event.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </>
                                    )}
								</Grid>	
							</Box>		
						</Box>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}