import { extendTheme } from '@mui/joy/styles';
	
const CheckBoxStyle = extendTheme({
	components: {
		JoyCheckbox: {
			defaultProps: {
				color: "neutral",
				variant: "outlined",
				checkedIcon: <div style={{width: "75%", height: "75%", background: "#276CF2", borderRadius: "1px"}}></div>,
				uncheckedIcon: <div style={{width: "75%", height: "75%"}}></div>
			},
			styleOverrides: {
				root: {
					"span": {
						borderWidth: "2px",
						borderColor: "#5E5F62"
					}
				}
			},
		},
	},
});

export default CheckBoxStyle;