import { AmpModelIcons } from "./Dictionaries/AmpModelIcons.ts";
import { Interpreter } from "../../../../../../Base/TreeView/IconInterpterer/Interpreter.ts";
import { ITreeViewIcon } from "../../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";
import { AmpModelIconContext } from "./Context/AmpModelIconContext.ts";

export class AmpModelIconInterpreter extends Interpreter<AmpModelIconContext> {
    public Interpret(context: AmpModelIconContext): ITreeViewIcon | undefined {
        if (context.isRootProject)
            return AmpModelIcons.ReportModelProject;
        if (context.isRootPlane)
            return AmpModelIcons.Plane;
        if (context.isInnerTask)
            return AmpModelIcons.PurpleBox;

        return this.isTaskCondition(context.isCyclicCheck) 
            ? AmpModelIcons.Refresh 
            : AmpModelIcons.TaskL;
    }

    private isTaskCondition(isCyclicCheck : boolean | null) {
        return isCyclicCheck == true;
    }
}