import * as React from 'react';

import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';

import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';

export default function Assys(props) {
	//Стили
	const inputStyle = { borderRadius: 0, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'16px' }
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'14px', alignSelf:'center' }

	const handleClose = () => {
		props.setOpen(false);
	};

	let xcenter = (window.innerWidth/2)-1600;
	let ycenter = (window.innerHeight/2)-400;

	const [isDragging, setIsDragging] = React.useState(false);
  	const [position, setPosition] = React.useState({ x: xcenter, y: ycenter });
  	const [startPosition, setStartPosition] = React.useState({ x: 0, y: 0 });
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({
			x: e.clientX - position.x,
			y: e.clientY - position.y,
		});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setPosition({
			x: e.clientX - startPosition.x,
			y: e.clientY - startPosition.y,
		});
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
	};

    const [data, setData] = React.useState([])
	const [filters, setFilters] = React.useState({ipcPos:'', majorAssy:true, noAssy:false, lowerAssy:false, lowerPart:false})

	//Изменение фильтров
	const handleChangeFilters = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};
   
	const fetchData= async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_PARTM}/AmpModelPositions/ExistingPosition(ampId=${props.ampId},showMajorAssy=${filters.majorAssy},showNotAssy=${filters.noAssy},showLowerAssy=${filters.lowerAssy},showLowerPart=${filters.lowerPart},ipcPos='${filters.ipcPos}')`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setData(result);
		} else {
			//props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
		}
	};

    React.useEffect(() => {
		fetchData();
	}, []);

	React.useEffect(() => {
		fetchData();
	}, [filters]);

	return (
		<React.Fragment>
			<div
				style={{
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'rgba(0, 0, 0, 0.3)',
					zIndex: 10,
					overflow:'hidden',
				}}
       		/>
				<Sheet
					variant="plain"
					sx={{
						height:'600px',
						width:'1000px',
						borderRadius: '0px',
						position: 'absolute',
						border:'1px solid black',
						zIndex:11,
						left: position.x,
						top: position.y,
                        bgcolor:'#d6d3ce'
					}}
				>
					<Box
						sx={{
							display:'grid',
							gridTemplateRows:'20px 500px 80px',
						}}
					>
						<Box
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							sx={{
								display:'flex',
								alignItems:'center',
								
								cursor: isDragging ? 'grabbing' : 'grab',
							}}
						>
							<Typography 
								sx={{
									fontSize:'15px',
									color:'black',
                                    ml:1,
                                    fontWeight:'bold'
								}}
							>
								Select Existing Position to Add New Position:
							</Typography>
						</Box>
						<Box
                            sx={{
                                height:'500px',
                                mx:1,
                                borderRadius:'0px',
                                bgcolor:'white',
								border:'1px solid black'
                            }}
                        >
                            <AutoSizer>
                                {({ height, width }) => (
                                    <Table
                                        gridStyle={{ outline: 'none' }}
                                        width={width-2}
                                        height={height-2}
                                        headerHeight={0}
                                        rowHeight={20}
                                        rowCount={data.length}
                                        rowGetter={({ index }) => data[index]}>
                                        <Column width={150} dataKey="id" />
                                        <Column width={100} dataKey="assyStatus" />
                                        <Column width={300} dataKey="ipcPosition" />
                                        <Column width={200} dataKey="position" />
                                        <Column width={width} dataKey="description" />
                                    </Table>
                                )}
                            </AutoSizer>
                        </Box>
						<Box
							sx={{
								height:'100%',
								display:'flex',
								width:'100%',
								justifyContent:'space-between',
								alignItems:'center',
								p:1
							}}
						>
							<FormControl sx={{width: '150px'}}>
								<FormLabel sx={labelStyle}>Filter IPC Pos.:</FormLabel>
								<Input 
									sx={{
										...inputStyle, 
									}} 
									value={filters?.ipcPos || ''}
									onChange={(event) => handleChangeFilters('ipcPos', event.target.value)}
								/>
							</FormControl>
							<Tooltip title='Show Major Assy'>
								<Checkbox 
									label=": Major Assy"
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={filters?.majorAssy}
									onChange={(event) => handleChangeFilters('majorAssy', event.target.checked)}
								/>
							</Tooltip>
							<Tooltip title='Show No Assy'>
								<Checkbox 
									label=": No Assy"
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={filters?.noAssy}
									onChange={(event) => handleChangeFilters('noAssy', event.target.checked)}
								/>
							</Tooltip>
							<Tooltip title='Show Lower Assy'>
								<Checkbox 
									label=": Lower Assy"
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={filters?.lowerAssy}
									onChange={(event) => handleChangeFilters('lowerAssy', event.target.checked)}
								/>
							</Tooltip>
							<Tooltip title='Show Lower Part'>
								<Checkbox 
									label=": Lower Part"
									variant="outlined"
									color="neutral"
									sx={{
										fontSize:'14px',
										'--Checkbox-size':'15px',
										gap:0.4,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
									checked={filters?.lowerPart}
									onChange={(event) => handleChangeFilters('lowerPart', event.target.checked)}
								/>
							</Tooltip>
							<Box>
								<Button
									//onClick={handleClose}
									sx={{
										borderRadius:0,
										height: 40, 
										minHeight: 0,
										width:'100px',
										bgcolor:'#d6d3ce',
										color:'black',
										border:'2px outset grey',
										fontWeight:'100'
									}}
								>
									Add
								</Button>
								<Button
									onClick={handleClose}
									sx={{
										borderRadius:0,
										height: 40, 
										minHeight: 0,
										width:'100px',
										bgcolor:'#d6d3ce',
										color:'black',
										border:'2px outset grey',
										ml:2,
										fontWeight:'100'
									}}
								>
									Cancel
								</Button>
							</Box>
						</Box>
					</Box>
				</Sheet>
		</React.Fragment>
	);
}