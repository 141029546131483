import * as React from 'react';

import Box from '@mui/joy/Box';
import Tab from '@mui/joy/Tab';
import Tabs from '@mui/joy/Tabs';
import Input from '@mui/joy/Input'
import TabList from '@mui/joy/TabList';
import TabPanel from '@mui/joy/TabPanel';
import Typography from '@mui/joy/Typography';
import CssBaseline from '@mui/joy/CssBaseline';
import { CssVarsProvider } from '@mui/joy/styles';

import Header from '../../Components/Header.tsx';

import AMP from './AMP/AMP.tsx'
import Pos_Struct from './Pos_Struct/Pos_Struct.tsx'
import MR from './MR/MR.tsx'
import Model from './Model/Model.tsx'
import Plan from './Plan/Plan.tsx'
import Pos_AMP_MR from './Pos_AMP_MR/Pos_AMP_MR.tsx'
import Task_Effectivity from './Task_Effectivity/Task_Effectivity.tsx';
import MRB_Category from './MRB_Category/MRB_Category.tsx';

import URL from '../../../../URL.js'

export default function Administrative() {
	document.body.style.backgroundColor = '#d6d3ce';
	document.title = 'AMP'
	const tabStyle = {fontSize:'14px'}
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };

	const selectedACFamily = sessionStorage.getItem('AMP_SelectedACFamily')
	const selectedOperator = sessionStorage.getItem('AMP_SelectedOperator')

	const [selectedTab, setSelectedTab] = React.useState (sessionStorage.getItem('AMP_screen')?sessionStorage.getItem('AMP_screen'):'AMP');
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})
	const [ampList, setAmpList] = React.useState([])
	const [selectedAmp, setSelectedAmp] = React.useState()
	const handleChangeTab = (newValue) => {
		sessionStorage.setItem('AMP_screen', newValue ? newValue : 'AMP');
		setSelectedTab(newValue);
	}

	//Запрос списка AMP
	const fetchData = async () => {
		const response = await fetch(`${URL}${process.env.REACT_APP_API_PARTM}/Amp?$filter=acFamily eq '${selectedACFamily}'`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setAmpList(result.value)
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		if (!sessionStorage.getItem('AMP_screen')) {
			sessionStorage.setItem('AMP_screen','AMP');
		}
		fetchData();
	}, []);

	
	React.useEffect(() => {
		if(ampList.length !== 0 ){
			if (sessionStorage.getItem('ampList')) {
				//sessionStorage.setItem('selectedAmp', JSON.stringify(ampList?.find(obj => obj.draft === 'N')));
				if(arraysEqual(ampList, JSON.parse(sessionStorage.getItem('ampList')))){
					setSelectedAmp(JSON.parse(sessionStorage.getItem('selectedAmp')))
					
				} else {
					sessionStorage.setItem('ampList', JSON.stringify(ampList));
					sessionStorage.setItem('selectedAmp', JSON.stringify(ampList?.find(obj => obj.draft === 'N')));
					setSelectedAmp(ampList?.find(obj => obj.draft === 'N'))
				}
				
			} else {
				sessionStorage.setItem('ampList', JSON.stringify(ampList));
				sessionStorage.setItem('selectedAmp', JSON.stringify(ampList?.find(obj => obj.draft === 'N')));
				setSelectedAmp(ampList?.find(obj => obj.draft === 'N'))
			
			}
		}
	}, [ampList]);
	
	function arraysEqual(arr1, arr2) {
		if (arr1.length !== arr2.length) return false;
		return arr1.every((obj, index) => {
			return JSON.stringify(obj) === JSON.stringify(arr2[index]);
		});
	}

	return (
		<CssVarsProvider disableTransitionOnChange>
			<CssBaseline />
			<Box sx={{ display: 'flex', minHeight: '100dvh', minWidth:'1100px' }}>
				<Header title="Aircraft's Maintenance Program"/>
				<Box
					component="main"
					className="MainContent"
					sx={{
						px: 2,
						pt:'calc(var(--Header-height))',
						pb: 1,
						flex: 1,
						display: 'flex',
						flexDirection: 'column',
						minWidth: 0,
						height: '100dvh',
					}}
				>
					<Box
						sx={{
							height:'50px',
							display:'flex',
							position:'relative',
							alignItems:'flex-end',
							justifyContent:'center',
							mt:0.5
						}}
					>
						<Box
							sx={{
								border:'1px solid black',
								borderBottom:'none',
								height:'auto',
								width:'auto',
								display:'flex',
								alignItems:'center',
								p:0.5,
								position:'relative'
							}}
						>
							<Box
								sx={{
									color:'#000084',
									position:'absolute',
									top:-8,
									fontSize:10,
									bgcolor:'#d6d3ce'
								}}
							>
								Selected AMP:
							</Box>
							<Typography>
								AC Family:
							</Typography>
							<Input  
								sx={inputStyle} 
								value={selectedACFamily || ''}
								
							/>
							<Input 
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={selectedOperator?.split('/')[0] || ''}
							/>
							<Input 
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={selectedOperator?.split('/')[1] || ''}
							/>
						</Box>
						<Box
							sx={{
								position:'absolute',
								right:10,
								display:selectedAmp?'flex':'none',
								color:'black'
							}}
						>
							{selectedAmp?.draft === 'N' ? 'Active ' : 'Draft '} AMP - ID: {selectedAmp?.id}  
						</Box>
					</Box>
					<Tabs
						value={selectedTab}
						onChange={(event, newValue) => handleChangeTab(newValue)}
						sx={{
							flexDirection: 'column',
							bgcolor:'#d6d3ce',
							whiteSpace: 'nowrap',
							border:'1px solid black',
							position:'relative',
							height:'100%'
						}}
					>
						<TabList >
							<Tab value="AMP" sx={tabStyle}>
								AMP
							</Tab>
							<Tab value="PS" sx={tabStyle}>
								AMP Pos Struct
							</Tab>
							<Tab value="MR" sx={tabStyle}>
								AMP MR
							</Tab>
							<Tab value="M" sx={tabStyle}>
								AMP Model
							</Tab>
							<Tab value="P" sx={tabStyle}>
								AMP Plan
							</Tab>
							<Tab value="PAM" sx={tabStyle}>
								POS-AMP MR
							</Tab>
							<Tab value="TE" sx={tabStyle}>
								Task Effectivity
							</Tab>
							<Tab value="MRB" sx={tabStyle}>
								MRB Category
							</Tab>
						</TabList>
						<TabPanel value="AMP" sx={{p:'0px'}}>
							<AMP selectedACFamily={selectedACFamily} selectedOperator={selectedOperator} selectedAmp={selectedAmp} setSelectedAmp={setSelectedAmp} URL={URL}/>
						</TabPanel>
						<TabPanel value="PS" sx={{p:'0px'}}>
							<Pos_Struct URL={URL} selectedACFamily={selectedACFamily} selectedAmp={selectedAmp}/>
						</TabPanel>
						<TabPanel value="MR" sx={{p:'0px'}}>
							<MR URL={URL} selectedAmp={selectedAmp}/>
						</TabPanel>
						<TabPanel value="M" sx={{p:'0px'}}>
							<Model URL={URL}/>
						</TabPanel>
						<TabPanel value="P" sx={{p:'0px'}}>
							<Plan URL={URL} selectedACFamily={selectedACFamily} selectedAmp={selectedAmp}/>
						</TabPanel>
						<TabPanel value='PAM' sx={{p:'0px'}}>
							<Pos_AMP_MR URL={URL} selectedACFamily={selectedACFamily} selectedAmp={selectedAmp}/>
						</TabPanel>
						<TabPanel value='TE' sx={{p:'0px'}}>
							<Task_Effectivity URL={URL} selectedACFamily={selectedACFamily} selectedAmp={selectedAmp}/>
						</TabPanel>
						<TabPanel value='MRB' sx={{p:'0px'}}>
							<MRB_Category URL={URL} selectedAmp={selectedAmp} selectedACFamily={selectedACFamily}/>
						</TabPanel>
					</Tabs>		
				</Box>
			</Box>
		</CssVarsProvider>
	);
}
