import React from "react";

import URL from '../../../../../../URL.js';
import { AmpDepTreeViewProps } from "./Props/AmpDepTreeViewProps.ts";
import Tree, { useTreeState } from "../../../../../Base/TreeView/index.tsx";

import { AmpPlanMaintIconInterpreter } from "./IconInterpreters/AmpPlanMaintIconInterpreter.ts";
import { IIconContext } from "../../../../../Base/TreeView/IconInterpterer/IIconContext.ts";
import { Interpreter } from "../../../../../Base/TreeView/IconInterpterer/Interpreter.ts";
import { ITreeViewIcon } from "../../../../../Base/TreeView/IconInterpterer/ITreeViewIcon.ts";

export default function AmpPlanMaintenanceTree(props : AmpDepTreeViewProps) {
	const [responseStatus, setResponseStatus] = React.useState({
		errorStatus:0, 
		successfulStatus:''
	});
	const [treeData, setTree] = React.useState<{
		id: number, 
		image: ITreeViewIcon
	}[]>([]);
	const { required, handlers } = useTreeState({ 
		id: 'tree', 
		data: treeData, 
		defaultOpened: [1], 
		multipleSelect: false 
	});

	// Задание интерпретатора для выбора иконок и базовой структуры дерева
	const iconInterpreter = new AmpPlanMaintIconInterpreter();
    const treeBaseData: [] = [];

	// Обновление данных при выборе position 
    React.useEffect(() => {
        if (props.positionId > 0) {
            fetchTreeRootData();
            fetchTreeData(iconInterpreter);
        }
    }, [props.positionId]);

	// Корневой узел
	const treeDataRoot = [
        {
			isRootData: true,
			id: 1,
			image: iconInterpreter.Interpret(getTreeViewIconParams(undefined, true)),
			header: { id: { value: 'Part Maintenance Plan:' } },
			maxWidth: 500,
			children: []
		}];

    // Получение базовой информации в шапке
    const fetchTreeRootData = async () => {
        Object.assign(treeBaseData, treeDataRoot);
    }
    
    // Получение общей информации для дерева
    const fetchTreeData = async (iconInterpreter: Interpreter<IIconContext>) => {
		if (treeBaseData?.length == 0) 
			return;

		const response = await fetchResponseAsync();
        if (response.ok) {
            const result = await response.json();
            treeBaseData[0].children = [];
    
            result?.forEach(element => {
                const treeViewIconParams = getTreeViewIconParams(element);
				const headerParams = getNodeHeaderParams(element);

                const child = getChildNode(
					iconInterpreter.Interpret(treeViewIconParams),
					headerParams
				);
                treeBaseData[0].children.push(child);
            });
    
            setTree(treeBaseData);
        } 
		else {
            setResponseStatus({...responseStatus, errorStatus:response.status});
        }
    };

    // Получение информации о children
	const fetchTreeChildrenData = async (node: any) : Promise<any> => {
		const response = await fetchResponseAsync(true, node);
		if (response.ok) {
			const result = await response.json();
			let childNode = { children: [{}] };
            childNode.children = [];

			result.forEach(element => {
				const headerParams = getChildrenNodeHeaderParams(element);

				const child = {
                    id: 2,
                    header: headerParams,
                    maxWidth: 500
                };
				childNode.children.push(child);
			});
            
			return childNode;
		} 
		else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	const fetchResponseAsync = async (isChild: boolean = false, node: any = undefined): Promise<Response> => {
        const url = isChild
            ? `${URL}${process.env.REACT_APP_API_PARTM}/AmpModelMps/PartMaintenancePlanTreatments(MpId=${node.data.header.id.value})`
			: `${URL}${process.env.REACT_APP_API_PARTM}/AmpModelMps/PartMaintenancePlan(PositionId=${props.positionId},AmpId=${props.ampId})`;
			
		const headers = { 
			headers: { 'Authorization': `Bearer ${localStorage.getItem('asid')}` }
		};

		return await fetch(url, headers);
    }

    function getTreeViewIconParams(element: any, isRoot: boolean = false): any {
		return { 
			positionEffectivity: element?.positionEffectivity,
			isRoot: isRoot
		};
	}

	function getNodeHeaderParams(element: any): any {
		const mainPnValue = `Associated TC Reference: ${element.tcReference}`;

		return { 
			id: { value: element.id, width: 70 }, 
            treatment: { value: element.treatment, width: 50 }, 
            treatmentDescription: { value: element.treatmentDescription, width: 150 }, 
            mainPn: { value: mainPnValue, width: 350 }
		};
	}

    function getChildrenNodeHeaderParams(element: any): any {
        var typeString = '';
		var description = `${element.description}: `;
        var fhString = `${element.fh} FH; `;
        var fcString = `${element.fc} FC; `;
        var calendarString = `${element.calendarValue} ${element.calendar}; `;
		
        typeString = typeString.concat(typeString, 
			element.description != null ? description : '',
            element.fh != null ? fhString : '',
            element.fc != null ? fcString : '',
            element.calendarValue != null ? calendarString : ''
        );

		return { 
			type: { value: typeString, width: 500 }, 
            pnEff: element.pn != null 
                ? { value: `PN Eff.: ${element.pn}` }
                : { value: '' }
		};
	}

    function getChildNode(interpreterResult: any, headerParams: any, conditionItem: any = undefined): any {
		const isExtended = conditionItem == undefined;
		const simpleChild = {
			id: 1,
			image: interpreterResult,
			header: headerParams
		};
		const extendedChild = {
			...simpleChild,
			children: [{
				...simpleChild,
				header: { id: { value: 'No content' } }
			}]
		};

		return isExtended ? extendedChild : simpleChild;
	}

	const openNodeAsync = async (node) => {
		if (!node.isOpened() && node.data.header?.id?.value > 0) {
			const childNode = await fetchTreeChildrenData(node);
			handlers.setRawChildren(node, childNode.children, undefined, true);
		}
		handlers.setOpen(node);
	}

    return (
      	<Tree
			{...required}
			{...handlers}
			gapMode="padding" 
			depthGap={20}
            setOpen={openNodeAsync}
			setSelected={(node, selected) => {
				handlers.setSelected(node, selected);
				props.selectVariant('MP');
				props.setSelectedMP(node.data.header.id.value)
			}}
		/>
    );
}