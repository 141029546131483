import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

//Иконки
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

//Компоненты 
import Requirements from './Requirements.tsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';
import AmpPlanTree from './TreeViews/AmpPlanTree.tsx';

export default function Plan(props) {

	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1,}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'16px',alignSelf:'center'};

	const [filters, setFilters] = React.useState({id:0, ataCode:'', title:'', acFamily:''});
	const [showEditor, setShowEditor] = React.useState (true);
	const [showIntervalFilters, setShowIntervalFilters] = React.useState(false)
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос списка AC Families
	const fetchFamilies = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftFamilies?$select=acfamily`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			//setAcFamilies(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		//fetchFamilies();
	}, []);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, ataCode:'', title:'', acFamily:''});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				id='main'
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:showEditor?'50% 50%':'100%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							left:16
						}}
					>
						Maintenance Plan:
					</Typography>
					<Box
						id='buttons'
						sx={{
							display:'flex',
							alignItems:'center',
							justifyContent:'center',
							position:'absolute',
							right:16,
							height:40
						}}
					>
						<Tooltip title='Reset Filters'>
							<IconButton
								variant='plain'
								onClick={refreshFilters}
								sx={{
									m:0,
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/refresh.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
						<Tooltip title= {showEditor? 'Hide Editor' : 'Show Editor'}>
							<IconButton
								variant='plain'
								onClick={toggleEditore} 
								sx={{
									m:0,
									transition: '0.3s',
									transform: showEditor? 'rotate(0deg)' : 'rotate(180deg)',
								}}
							>
								<img 
									width='35px' 
									src="/images/svg/arrowBack.svg" 
									alt="" 
								/>
							</IconButton>
						</Tooltip>
					</Box>	
					<Box
						id='filters'
						sx={{
							display:'flex',
							flexWrap: 'nowrap',
							gap: 0.5,
							alignItems:'flex-end',
							mt:'20px',
							mx:'16px',
						}}
					>
						<FormControl>
							<FormLabel sx={lableStyle}>Task:</FormLabel>
							<Input
								value={filters.ataCode}
								onChange={(event) => handleChange('ataCode', event.target.value)}
								sx={{...inputStyle, width:200}}
							/>
						</FormControl>
						<Box
							sx={{
								position:'relative'
							}}
						>
							<Tooltip title= 'Open Interval, Check Filters'>
								<Button	
									onClick={()=>setShowIntervalFilters(!showIntervalFilters)}	
									color='neutral'
									sx={{
										borderRadius:0,
										height: 30, 
										minHeight: 0,
										width:50,
										whiteSpace:'wrap',
										fontSize:12,
										mx:1,
										mb:1,
										bgcolor:'#c7c3fe',
										color:'black',
										border:'2px outset grey',
										lineHeight:1
									}}
								>
									Interval
								</Button>
							</Tooltip>
							{showIntervalFilters && (
								<Box
									sx={{
										position:'absolute',
										height:240,
										width:260,
										bgcolor:'#d6d3ce',
										border:'1px solid black',
										zIndex:999,
										left:'-100px',
										top:40,
										alignItems:'center'
									}}
								>
									<Grid container spacing={1} sx={{ flexGrow: 1, px:2, py:1 }}>
										<Grid xs={2} sx={{color:'black'}}>
											FH:
										</Grid>
										<Grid xs={10}>
											<Input
												value={filters.title}
												onChange={(event) => handleChange('title', event.target.value)}
												sx={{...inputStyle}}
											/>
										</Grid>

										<Grid xs={2} sx={{color:'black'}}>
											FC:
										</Grid>
										<Grid xs={10}>
											<Input
												value={filters.title}
												onChange={(event) => handleChange('title', event.target.value)}
												sx={{...inputStyle}}
											/>
										</Grid>

										<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
											<Typography sx={{color:'black',fontSize:16}}>
												DY:
											</Typography>
											<Tooltip title='Days Selector'>
												<Checkbox 
													variant="outlined"
													color="neutral"
													sx={{
														fontSize:'14px',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
													//checked={editedData.shift}
													//onChange={(event) => handleChange('shift', event.target.checked)}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
											<Typography sx={{color:'black',fontSize:16}}>
												MO:
											</Typography>
											<Tooltip title='Months Selector'>
												<Checkbox 
													variant="outlined"
													color="neutral"
													sx={{
														fontSize:'14px',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
													//checked={editedData.shift}
													//onChange={(event) => handleChange('shift', event.target.checked)}
												/>
											</Tooltip>
										</Grid>
										<Grid xs={4} sx={{display:'flex', alignItems:'center', justifyContent:'center', gap:0.5}}>
											<Typography sx={{color:'black',fontSize:16}}>
												YR:
											</Typography>
											<Tooltip title='Years Selector'>
												<Checkbox 
													variant="outlined"
													color="neutral"
													sx={{
														fontSize:'14px',
														'--Checkbox-size':'15px',
														gap:0.4,
														'& .css-r4cpcz-JoyCheckbox-checkbox': {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
														'& .css-n110n' : {
															borderRadius:0,
															'--joy-palette-neutral-outlinedBorder':'black',
														},
													}}
													//checked={editedData.shift}
													//onChange={(event) => handleChange('shift', event.target.checked)}
												/>
											</Tooltip>
										</Grid>
												
										<Grid xs={2}></Grid>
										<Grid xs={8}>
											<Input
												value={filters.title}
												onChange={(event) => handleChange('title', event.target.value)}
												sx={{...inputStyle}}
											/>
										</Grid>
										<Grid xs={2}></Grid>

										<Grid xs={2}></Grid>
										<Grid xs={4}>
											<Radio
												color='neutral' 
												variant='outlined'
												label='And'
											/>
										</Grid>	
										<Grid xs={4}>
											<Radio
												color='neutral' 
												variant='outlined'
												label='Or'
											/>
										</Grid>	
										<Grid xs={2}></Grid>

										<Grid xs={12} sx={{display:'flex', justifyContent:'center'}}>
											<Button
												//onClick={handleConfirm}
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Ok
											</Button>
											<Button
												//onClick={handleConfirm}
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Cancel
											</Button>
											<Button
												//onClick={handleConfirm}
												sx={{
													borderRadius:0,
													height: 25, 
													minHeight: 0,
													bgcolor:'#d6d3ce',
													color:'black',
													border:'2px outset grey',
												}}
											>
												Reset
											</Button>
										</Grid>
									</Grid>
								</Box>
							)}
						</Box>
						<FormControl>
							<FormLabel sx={lableStyle}>Type:</FormLabel>
							<Autocomplete
								options={[]}
								sx={{...inputStyle, width:200}}
								onChange={(event, value) => handleChange('acFamily', value ? value.label : '')}
								value={!filters.acFamily ? null : { label: filters.acFamily}}
							/>
						</FormControl>
					</Box>
					<Box 
						sx={{
							width: '100%',
							display:'grid',
							gridTemplateColumns:'auto 50px'
						}}
					>
						<Sheet
							id='tablesSheet'
							variant="outlined"
							sx={{
								position:'absolute',
								width: `calc(100% - 86px)`,
								height:`calc(100% - 85px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
								mt:'0px',
								mb:'10px'
							}}
						>
							<AmpPlanTree
								//setPositionId={setPositionId}
								acFamily={props.selectedACFamily}
								ampId={5}
								taskFilter=''
								checkFilter=''
								fhFilter={0}
								fcFilter={0}
								calendarKindFilter=''
								calendarValueFilter={0}
								resourceOrFIlter={true}
							/>
						</Sheet>
						<Box
							sx={{
								position:'absolute',
								right:0,
								display:'flex',
								flexDirection:'column'
							}}
						>
							<Button	
								//onClick={()=>setShowIntervalFilters(!showIntervalFilters)}	
								color='neutral'
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									width:50,
									whiteSpace:'wrap',
									fontSize:12,
									mx:1,
									mb:1,
									bgcolor:'#c7c3fe',
									color:'black',
									border:'2px outset grey',
									lineHeight:1
								}}
							>
								<KeyboardArrowLeftIcon/>
							</Button>
							<Button	
								//onClick={()=>setShowIntervalFilters(!showIntervalFilters)}	
								color='neutral'
								sx={{
									borderRadius:0,
									height: 30, 
									minHeight: 0,
									width:50,
									whiteSpace:'wrap',
									fontSize:12,
									mx:1,
									mb:1,
									bgcolor:'#c7c3fe',
									color:'black',
									border:'2px outset grey',
									lineHeight:1
								}}
							>
								<KeyboardArrowRightIcon/>
							</Button>
						</Box>
					</Box>
				</Box>
				<Box
					id='requirementsArea'
					sx={{
						display:showEditor?'flex':'none',
						position:'relative',
					}}
				>
					<Requirements
						URL={props.URL}
						selectedAmp={props.selectedAmp}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
