import React, { useState, useEffect } from "react";
import buildQuery from "odata-query";
import Table from "@mui/joy/Table";
import Checkbox from "@mui/joy/Checkbox";
import CircularProgress from "@mui/joy/CircularProgress";
import Header from "../../Layout/Header";
import Application from "../../../Application";
import { CssVarsProvider } from "@mui/joy/styles";
import CheckBoxStyle from "../../../Theme/CheckBoxStyle.jsx";
import { toast, Zoom, ToastOptions} from "react-toastify";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import SearchIcon from "@mui/icons-material/Search";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import { Stack } from "@mui/material";
import Tooltip from "@mui/joy/Tooltip";
import IconButton from "@mui/joy/IconButton";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FormControl from "@mui/joy/FormControl";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import Divider from "@mui/joy/Divider";
import DialogTitle from "@mui/joy/DialogTitle";
import DialogContent from "@mui/joy/DialogContent";
import DialogActions from "@mui/joy/DialogActions";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import "./Dto/Model.tsx"
import MailTemplate from "./MailTemplate.tsx";
import ReportTemplate from "./ReportTemplate.tsx";
import Mail from "./Mail.tsx";
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export default function Report({isOpen = false, closeCommand, onSelect, onSelectCan, moduleName = "Destiny Herald", windowName = "", version = "", scheduler}) {
	const application = new Application();
	const baseUrl = application.getDestinyHeraldUrl();
	const endpointUrl = new URL("reports", baseUrl);
	const headers = { "Authorization": "Bearer " + localStorage.getItem("asid"), "Content-Type": "application/json" };
	const [isShowEditor, setIsShowEditor] = useState<boolean>(true);
	const [isShowSpinner, setIsShowSpinner] = useState<number>(0);
	const [reports, setReports] = useState<Array<ReportDto>>([]);
	const [selectedReport, setSelectedReport] = useState<ReportDto | null>(null);
	const [editableReport, setEditableReport] = useState<ReportDto | null>(null);
	const [selectedId, setSelectedId] = useState<string | null>(null);
	const [isOpenDeletionConfirmationDialog, setIsOpenDeletionConfirmationDialog] = useState<boolean>(false);
	const [changes, setChanges] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>("");
	const [timerId, setTimerId] = useState<any>(null);
	const [permission, setPermission] = useState([]);
	const [isShowMailTemplateWindow, setIsShowMailTemplateWindow] = useState<boolean>(false);
	const [isShowReportTemplateWindow, setIsShowReportTemplateWindow] = useState<boolean>(false);
	const [isShowMailWindow, setIsShowMailWindow] = useState<boolean>(false);
	const [mailTemplateType, setMailTemplateType] = useState<number>(0);
	const [numberRecipients, setNumberRecipients] = useState<number>(0);
	const [numberTemplates, setNumberTemplates] = useState<number>(0);
	var clickСheckbox = false;
	
	const toastOptions: ToastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	const styleTableHeader = {
		color: "white",
		fontWeight: "normal",
		fontSize: "15px",
		padding: "6.5pt",
		borderRadius: "0",
		width: "auto",
		whiteSpace: "nowrap",
	}

	const styleTable = {
		"--TableCell-headBackground": "#3B3D40",
		"--TableCell-paddingY": "3pt",
		"--TableCell-height": "16pt",
		"--TableCell-paddingX": ".5rem",
		"--TableCell-borderColor": "#C0BFBF",
		"--joy-palette-background-level2": "#EFEFEF",
		"--Table-headerUnderlineThickness": "1px",
		"--TableRow-hoverBackground": "#C3D0EA",
		tableLayout: "auto",
	};

	const styleTableCell = {
		width: "auto",
		whiteSpace: "nowrap",
	}

	const styleToolbarButton = {
		color: "#6B6B6B",
		"&:hover": {
			color: "#276CF2",
			background: "none"
		}
	}

	useEffect(() => {
			async function onMount() {
				let reports = await getReports();
				
				let reportId = scheduler?.reportId;
				
				if (reportId) {
					let item = reports.find(i => i.id === reportId);
						
					if (item) {
						item.isSelected = true;
						setSelectedId(reportId);
					}
				}

				setReports(reports);
				setPermission(await getPermission("Destiny Herald", "Reports"));
			}

			onMount();
	}, []);

	function seveSelectedCan() : boolean {
		return onSelectCan();
	}

	async function onSelectCommand() {
		onSelect(selectedId);
		closeCommand();
	}

	async function getPermission(moduleName: string, windowName: string) {
		try {	
			setIsShowSpinner(value => value + 1);
	
			const filter = [
				{ module: moduleName },
				{ window: windowName }
			];
				
			let query = buildQuery({filter});
	
			let url = application.getAdminUrl() + "self/permissions" + query;
	
			let response = await fetch(url, { method: "GET", headers: headers });
	
			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}
	
				let answer = await response.json();
					
				toast.error(<div>Failed get permissions<br/><hr/>{answer?.message}</div>, toastOptions);
				return "";
			}
	
			let content = await response.json();
	
			if (content.value.length > 0) {
				return content.value[0].access;
			}
			else {
				toast.error(<div>Error<br/><hr/>Missing permissions.</div>, toastOptions);
				return "";
			}
		}
		catch (ex) {
			toast.error(<div>Error<br/><hr/>Failed get permissions.</div>, toastOptions);
			return "";
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function getReports(value: string = "") : Promise<Array<ReportDto>> {
		try {
			setIsShowSpinner(value => value + 1);
			
			let query: string = "";

			if (value) {
				const filter = {
					or: [
						`startswith(fileName, '${value}')`,
						`startswith(queryString, '${value}')`,
						`startswith(checkQueryString, '${value}')`
					]
				};

				query = buildQuery({filter});
			}

			let response = await fetch(endpointUrl + query,
				{ method: "GET", headers: headers });

			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}
				
				let answer = await response.json();
				
				toast.error(<div>Error<br/><hr/>{answer?.message}</div>, toastOptions);
				return [];
			}
				
			let content = await response.json();
			return content.value;
		}
		catch {
			toast.error(<div>Error<br/><hr/>Failed get reports</div>, toastOptions);
			return [];
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function onRowClick(evernt, item: ReportDto) {
		if (clickСheckbox) {
			clickСheckbox = false;
			return;
		}
		
		let value;

		if (selectedReport?.id === item.id) {
			value = null;
		}
		else {
			value = item;	
		}

		setSelectedReport(value);
		setEditableReport(value === false ? value : {...value});
		setChanges(false);
		setNumberRecipients(await getNumberRecipients(value?.id));
		setNumberTemplates(await getNumberTemplates(value?.id));
	}

	function onCheckboxClick(itemId) {
		clickСheckbox = true;
		
		for (let i = 0; i < reports.length; i++) {
			if (reports[i]?.id == itemId) {
				reports[i].isSelected = !reports[i].isSelected;
				setSelectedId(reports[i].isSelected ? itemId : null);
			}
			else {
				if (reports[i].isSelected) {
					reports[i].isSelected = false;
				}
			}
		}

		setReports([...reports]);
	}
	
	function onEditReport(name: string, value: any) {
		let modifiedReport = {...editableReport, [name]: value} as ReportDto;
		
		setChanges(modifiedReport.queryString != selectedReport?.queryString ||
			modifiedReport.fileName != selectedReport?.fileName ||
			modifiedReport.checkQueryString != selectedReport?.checkQueryString ||
			modifiedReport.sendOneMail != selectedReport?.sendOneMail ||
			modifiedReport.sendMailWithoutAttachment != selectedReport?.sendMailWithoutAttachment);

		setEditableReport(modifiedReport);
	}

	function getRowColor(index: number, item: ReportDto) {
		if (selectedReport?.id === item.id) return "#C8C8C8";
		if (index % 2 != 0) return "#EFEFEF";
		return "white";
	}

	async function getNumberRecipients(reportId: string | undefined) : Promise<number> {
		if (!!reportId == false) return 0;

		try {	
			setIsShowSpinner(value => value + 1);

			let url = endpointUrl + "?$expand=Mails(select=id)&$select=mails&$filter=id eq " +
				(reportId ? reportId : "00000000-0000-0000-0000-000000000000");
	
			let response = await fetch(url, { method: "GET", headers: headers });
	
			if (response.status === 401) {
				Application.logout();
			}

			if (response.ok) {
				let answer = await response.json();
				return answer.value[0].mails.length;
			}
			else {
				let answer = await response.json();
				
				if (answer?.message) {
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed get number of recipients.</div>, toastOptions);
				}
				return 0;
			}
		}
		catch (ex) {
			toast.error(<div>Error<br/><hr/>Failed get number of recipients.</div>, toastOptions);
			return 0;
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function getNumberTemplates(reportId: string | undefined) : Promise<number> {
		if (!!reportId == false) return 0;

		try {	
			setIsShowSpinner(value => value + 1);

			let url = endpointUrl + "?$expand=Templates(select=id)&$select=templates&$filter=id eq " +
				(reportId ? reportId : "00000000-0000-0000-0000-000000000000");
	
			let response = await fetch(url, { method: "GET", headers: headers });
	
			if (response.status === 401) {
				Application.logout();
			}

			if (response.ok) {
				let answer = await response.json();
				return answer.value[0].templates.length;
			}
			else {
				let answer = await response.json();
				
				if (answer?.message) {
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed get number of templates.</div>, toastOptions);
				}
				return 0;
			}
		}
		catch (ex) {
			toast.error(<div>Error<br/><hr/>Failed get number of templates.</div>, toastOptions);
			return 0;
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function updateReport() {
		try {
			setIsShowSpinner(value => value + 1);

			var url = endpointUrl.href + "/" + selectedReport?.id
			
			let data = {...editableReport};
			delete data["isSelected"];

			let response = await fetch(url, {
				method: "PATCH",
				headers: headers,
				body: JSON.stringify(data)
			});

			if (response.status === 401) {
				Application.logout();
			}

			if (response.ok) {
				toast.success("Changes saved.", toastOptions);

				//#region Поиск элемента и смена состояния
						
				let item = reports.find(i => i.id === editableReport?.id);
				
				if (item) {
					if (item.fileName != editableReport?.fileName) item.fileName = editableReport?.fileName ?? "";
					if (item.queryString != editableReport?.queryString) item.queryString = editableReport?.queryString ?? "";
					if (item.checkQueryString != editableReport?.checkQueryString) item.checkQueryString = editableReport?.checkQueryString ?? "";
					if (item.sendOneMail != editableReport?.sendOneMail) item.sendOneMail = editableReport?.sendOneMail ?? false;
					if (item.sendMailWithoutAttachment != editableReport?.sendMailWithoutAttachment) item.sendMailWithoutAttachment = editableReport?.sendMailWithoutAttachment ?? false;
					if (item.mailTemplateId != editableReport?.mailTemplateId) item.mailTemplateId = editableReport?.mailTemplateId ?? null;
					if (item.mailTemplateIdWithoutAttachment != editableReport?.mailTemplateIdWithoutAttachment) item.mailTemplateIdWithoutAttachment = editableReport?.mailTemplateIdWithoutAttachment ?? null;
				}

				//#endregion

				setChanges(false);
			}
			else {
				let content = await response.json();
				
				if (content?.message) {
					toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed to save changes.<br/>Code: {response.status}</div>, toastOptions);
				}
			}
		}
		catch(e) {
			toast.error(<div>Error<br/><hr/>Failed to save changes.</div>, toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function addReport() {
		try {
			setIsShowSpinner(value => value + 1);

			let newItem = {...editableReport};
			delete newItem["id"];
			delete newItem["isSelected"];

			let response = await fetch(endpointUrl, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(newItem)
			});

			if (response.status === 401) {
				Application.logout();
			}
			if (response.ok) {
				toast.success("Report added.", toastOptions);

				setSelectedReport(null);
				setEditableReport(null);
				setReports(await getReports());
				
				setChanges(false);
			}
			else {
				let content = await response.json();
				
				if (content?.message) {
					toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed to add changes.<br/>Code: {response.status}</div>, toastOptions);
				}
			}
		}
		catch(e) {
			toast.error(<div>Error<br/><hr/>Failed to add report.</div>, toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function deleteReport() {
		try {
			setIsShowSpinner(value => value + 1);
			
			var url = endpointUrl.href + "/" + selectedReport?.id

			let response = await fetch(url,
				{ method: "DELETE", headers: headers });
			
			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}
				
				let answer = await response.json();
				
				toast.error(<div>Error<br/><hr/>{answer.Message}</div>, toastOptions);
				return;
			}

			let newList =
				reports.filter(i => i !== selectedReport);
			
			setSelectedReport(null);
			setEditableReport(null);
			
			setReports(newList);
		}
		catch(e) {
			toast.error("Failed to delete.", toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	function debounce (fn: () => void, d: number) {
		if (timerId) clearTimeout(timerId);	
		setTimerId(setTimeout(fn, d));
	}

	function onChangeSearchString(e) {
		setSearchString(e.target.value);
		
		debounce(async () => {			
			setReports(await getReports(e.target.value));
		}, 500);
	}

	function canAdd() : boolean {
		return editableReport?.fileName != null &&
			editableReport?.queryString != null &&
			editableReport?.checkQueryString != null;
	}

	function onSelectedMailTemplate(selectedMailTemplateId) {
		let isModified = 
			selectedMailTemplateId !== selectedReport?.mailTemplateId
		
		if (isModified) {
			setEditableReport({...editableReport, mailTemplateId: selectedMailTemplateId} as ReportDto);
		}

		setChanges(value => value || isModified);

 		setIsShowMailTemplateWindow(value => !value);
	}

	function onSelectedMailTemplateWithoutAttachment(selectedMailTemplateId) {
		let isModified = 
			selectedMailTemplateId !== selectedReport?.mailTemplateIdWithoutAttachment
		
		if (isModified) {
			setEditableReport({...editableReport, mailTemplateIdWithoutAttachment: selectedMailTemplateId} as ReportDto);
		}

		setChanges(value => value || isModified);

 		setIsShowMailTemplateWindow(value => !value);
	}

	function onSelectedMailTemplateCan(): boolean {
		return !!selectedReport;
	}

	function onSelectReportTemplates(selectedIds: Array<string>) {
	}

	function onSelectRepoerTemplatesCan() : boolean {
		return !!selectedReport;
	}

	function onSelectMailsCan() : boolean {
		return !!selectedReport;
	}

	return(
		<Modal open={isOpen} onClose={closeCommand}>
			<ModalDialog layout="fullscreen" sx={{padding: "0", margin: "0", whiteSpace: "none", gap: "0"}}>
				<div style={{height: "100%", display: "grid", transitionDuration: "300ms", gridTemplateColumns: isShowEditor ? "1fr 25%" : "1fr 0", gridTemplateRows: "auto auto 1fr auto auto", gridTemplateAreas: "'header header' 'toolbar editor' 'table editor' 'bottom-toolbar editor' 'footer footer'"}}>
					{/* Header */}
					<div style={{gridArea: "header"}}>
						<Header title={moduleName} version={version} />
					</div>
					
					{/* Toolbar */}
					<div style={{gridArea: "toolbar", padding: ".3rem 1.3rem .3rem .5rem", background: "#FBFCFE", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between"}}>
						<Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start"}}>
							<Tooltip title="BACK" variant="solid" placement="top">
								<IconButton onClick={closeCommand} variant="outlined" sx={styleToolbarButton}>
									<WestOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
								</IconButton>
							</Tooltip>

							<h3 style={{color: "#6B6B6B", margin: ".4rem 0 0 .5rem"}}>{windowName}</h3>
						</Stack>
						
						<Stack direction="row" spacing={1} sx={{ justifyContent: "flex-end"}}>
							<Input value={searchString} onChange={onChangeSearchString} endDecorator={<SearchIcon />} sx={{color: "#276CF2", fontWeight: "600"}} />
								
							{
								isShowEditor == false &&

								<Tooltip title="SHOW EDITOR" variant="solid" placement="top">
									<IconButton onClick={() => setIsShowEditor(value => !value)} variant="outlined" sx={styleToolbarButton}>
										<EditNoteRoundedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>
							}
						</Stack>
					</div>

					{/* Table */}
					<div style={{overflow: "auto", gridArea: "table", whiteSpace: "nowrap"}}>
							<Table borderAxis="both" stripe="even" size="sm" stickyHeader sx={{...styleTable, overflow: "auto"}}>
								<thead>
									<tr>
										<th style={{...styleTableHeader, width: "42px"}}></th>
										<th style={styleTableHeader}>File Name:</th>
										<th style={styleTableHeader}>Query:</th>
										<th style={styleTableHeader}>Check Query:</th>
										<th style={styleTableHeader}>Send One Mail:</th>
										<th style={styleTableHeader}>Send Without Attachment:</th>
									</tr>
								</thead>
								<tbody>
									{reports.map((row, index) => (
										<tr key={row.id} onClick={(event) => onRowClick(event, row)} style={{background: getRowColor(index, row)}}>
											<td style={{textAlign: "center"}}>
												<CssVarsProvider theme={CheckBoxStyle}>
													<Checkbox checked={row.isSelected ?? false} onClick={() => onCheckboxClick(row.id)} />
												</CssVarsProvider>
											</td>
											<td>{row.fileName}</td>
											<td>{row.queryString}</td>
											<td>{row.checkQueryString}</td>
											<td style={{textAlign: "center"}}>
												<CssVarsProvider theme={CheckBoxStyle}>
													<Checkbox checked={row.sendOneMail ?? false} />
												</CssVarsProvider>
											</td>
											<td style={{textAlign: "center"}}>
												<CssVarsProvider theme={CheckBoxStyle}>
													<Checkbox checked={row.sendMailWithoutAttachment ?? false} />
												</CssVarsProvider>
											</td>
										</tr>
									))}
								</tbody>
							</Table>
					</div>

					{/* Bottom Toolbar */}
					<div style={{gridArea: "bottom-toolbar", borderTop: "1px solid #C0BFBF"}}>
						<Stack direction="row" spacing={20} sx={{justifyContent: "center", margin: "1rem 0 .8rem 0"}}>
							<Button sx={{width: "8rem"}} onClick={onSelectCommand} disabled={!seveSelectedCan()}>Select</Button>
							<Button sx={{width: "8rem"}} onClick={closeCommand}>Clsoe</Button>
						</Stack>
					</div>
						
					{/* Editor */}
					<div style={{gridArea: "editor", overflow: "auto", boxShadow: "-7px 0px 8px -6px rgba(17, 17, 17, 0.22)"}}>
						{/* Toolbar */}
						<div style={{padding: ".3rem 1.3rem .3rem .5rem", gridArea: "toolbar", display: "grid", gridTemplateColumns: "auto 1fr"}}>
							<Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start"}}>
								<Tooltip title="ADD" variant="solid" placement="top">
									<IconButton onClick={addReport} disabled={!canAdd()} variant="plain" sx={styleToolbarButton}>
										<AddCircleOutlineOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>

								<Tooltip title="UPDATE" variant="solid" placement="top">
									<IconButton onClick={updateReport} variant="plain" sx={styleToolbarButton} disabled={!(changes && selectedReport != null)}>
										<SaveOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>

								<Tooltip title="RESET" variant="solid" placement="top">
									<IconButton onClick={() => {setSelectedReport(null); setEditableReport(null)}} disabled={!editableReport}  variant="plain" sx={styleToolbarButton}>
										<RestartAltOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>

								<Tooltip title="DELETE" variant="solid" placement="top">
									<IconButton onClick={() => setIsOpenDeletionConfirmationDialog(true)} variant="plain" sx={styleToolbarButton} disabled={!selectedReport}>
										<DeleteForeverOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>
							</Stack>

							<div style={{display: "flex", justifyContent: "end"}}>
								<Tooltip title="HIDE EDITOR" placement="top">
									<IconButton onClick={() => setIsShowEditor(value => value = !value)} sx={styleToolbarButton}>
										<CloseOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>
							</div>
						</div>
					
						{/* Form */}
						<div style={{ margin: "12px 9px" }}>
							<FormControl required>
								<FormLabel>File Name:</FormLabel>
								<Input placeholder="File Name" value={editableReport?.fileName ?? ""} onChange={e => onEditReport("fileName", e.target.value)} />
							</FormControl>

							<FormControl sx={{marginTop: "15px"}}>
								<FormLabel>Query Strign:</FormLabel>
								<Input size="md" placeholder="Query String" value={editableReport?.queryString ?? ""} onChange={e => onEditReport("queryString", e.target.value == "" ? null : e.target.value)} />
							</FormControl>

							<FormControl required sx={{marginTop: "15px"}}>
								<FormLabel>Check Query:</FormLabel>
								<Input size="md" placeholder="Check Query" value={editableReport?.checkQueryString ?? ""} onChange={e => onEditReport("checkQueryString", e.target.value)} />
							</FormControl>

							<Button variant="outlined" color="neutral" startDecorator={numberTemplates > 0 ? <CheckOutlinedIcon /> : null} sx={{height: "38px", marginTop: "15px", width: "100%", color: "#6B6B6B"}} onClick={() => setIsShowReportTemplateWindow(value => !value)}>Report Templates</Button>
							<Button variant="outlined" color="neutral" startDecorator={selectedReport?.mailTemplateId ? <CheckOutlinedIcon /> : null} sx={{height: "38px", marginTop: "15px", width: "100%", color: "#6B6B6B"}} onClick={() => {setMailTemplateType(0); setIsShowMailTemplateWindow(value => !value)}}>Mail Template</Button>
							<Button variant="outlined" color="neutral" startDecorator={numberRecipients > 0 ? <CheckOutlinedIcon /> : null} sx={{height: "38px", marginTop: "15px", width: "100%", color: "#6B6B6B"}} onClick={() => setIsShowMailWindow(value => !value)}>Recipients</Button>
								
							<FormControl sx={{marginTop: "20px"}}>
								<CssVarsProvider theme={CheckBoxStyle}>
									<Checkbox label="Send One Mail" checked={editableReport?.sendOneMail ?? false} onChange={e => onEditReport("sendOneMail", e.target.checked)} />
								</CssVarsProvider>
							</FormControl>

							<FormControl sx={{marginTop: "20px"}}>
								<CssVarsProvider theme={CheckBoxStyle}>
									<Checkbox label="Send Mail Without Attachment" checked={editableReport?.sendMailWithoutAttachment ?? false} onChange={e => onEditReport("sendMailWithoutAttachment", e.target.checked)} />
								</CssVarsProvider>
							</FormControl>

							<Button variant="outlined" color="neutral" startDecorator={selectedReport?.mailTemplateIdWithoutAttachment ? <CheckOutlinedIcon /> : null} sx={{height: "38px", marginTop: "15px", width: "100%", color: "#6B6B6B"}} onClick={() => {setMailTemplateType(1); setIsShowMailTemplateWindow(value => !value)}}>Mail Template Without Attachment</Button>
						</div>
					</div>

					{/* Footer */}
					<div style={{gridArea: "footer", background: "#3B3D40", color: "white", fontSize: "10pt", padding: ".2rem 1rem .3rem 1rem", display: "flex", justifyContent: "space-between"}}>
						<div>User: {localStorage.getItem("login")}</div>
						<div>Permission: {permission}</div>
					</div>
				</div>

				<Modal open={isOpenDeletionConfirmationDialog} onClose={() => setIsOpenDeletionConfirmationDialog(false)}>
					<ModalDialog variant="outlined" role="alertdialog">
						<DialogTitle>
							<WarningRoundedIcon />
							Confirmation
						</DialogTitle>
						<Divider />
						<DialogContent>Are you sure you want to deleted report?</DialogContent>
						<DialogActions>
							<Button variant="solid" color="danger" onClick={() => {setIsOpenDeletionConfirmationDialog(false); deleteReport()}}>
								Yes
							</Button>
							<Button variant="plain" color="neutral" onClick={() => setIsOpenDeletionConfirmationDialog(false)}>
								No
							</Button>
						</DialogActions>
					</ModalDialog>
				</Modal>

				{
					isShowSpinner != 0 &&

					<div style={{position: "absolute", width: "100%", height: "100dvh", zIndex: 10}}>
						<CircularProgress color="primary" sx={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", "--CircularProgress-size": "100px", zIndex: "10"}}>
							<span style={{fontSize: "120%", color: "#3B3D40", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", textWrap: "nowrap"}}>Please wait ...</span>
						</CircularProgress>
					</div>

				}

				{
					isShowMailTemplateWindow &&
								
					<MailTemplate
						moduleName={document.title}
						windowName={mailTemplateType === 0 ? "Mail Templates" : "Mail Templates Without Attachment"}
						version={version}
						isOpen={isShowMailTemplateWindow}
						onClose={() => setIsShowMailTemplateWindow(value => !value)}
						onSelect={mailTemplateType === 0 ? onSelectedMailTemplate : onSelectedMailTemplateWithoutAttachment}
						onSelectCan={onSelectedMailTemplateCan}
						currentId={mailTemplateType === 0 ? editableReport?.mailTemplateId : editableReport?.mailTemplateIdWithoutAttachment} />
				}

				{
					isShowReportTemplateWindow &&
								
					<ReportTemplate
						moduleName={document.title}
						windowName="Report Templates"
						version={version}
						isOpen={isShowReportTemplateWindow}
						onClose={async () => {setNumberTemplates(await getNumberTemplates(selectedReport?.id)); setIsShowReportTemplateWindow(value => !value)}}
						onSelectCan={onSelectRepoerTemplatesCan}
						reportId={selectedReport?.id} />
				}

				{
					isShowMailWindow &&
								
					<Mail
						moduleName={document.title}
						windowName="Recipients"
						version={version}
						isOpen={isShowMailWindow}
						onClose={async () => {setNumberRecipients(await getNumberRecipients(selectedReport?.id)); setIsShowMailWindow(value => !value)}}
						onSelectCan={onSelectMailsCan}
						reportId={selectedReport?.id} />
				}

			</ModalDialog>
		</Modal>
	);
}