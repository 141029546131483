import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Radio from '@mui/joy/Radio';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';
import Divider from '@mui/joy/Divider';

import CircularProgress from '@mui/material/CircularProgress';

//Иконки
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

//Компоненты 
import AddEditeForm from './AddEditeForm.jsx';
import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';
import AmpModelTree from './TreeViews/AmpModelTree.tsx';

export default function Model(props) {

	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1,}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'16px',alignSelf:'center'};
	const headerStyle = {width: 'auto',fontWeight:'normal',fontSize:'16px',color:'black',};
	const rowStyle = {whiteSpace: 'nowrap', width: 'auto',fontSize:'18px'};
	const filtersStyle = {position:'absolute',width:'100%',top:0,minWidth:0,minHeight:0,height:'100%',px: 0.5,m: 0,borderRadius: '0px',"--Input-decoratorChildHeight": "24px",};

	const [data, setData] = React.useState<{id:number, ataCode:string, title:string, acFamily:string, etopsSignificant:boolean}[]>([]);
	const [filteredData, setFilteredData] = React.useState <{id:number, ataCode:string, title:string, acFamily:string, etopsSignificant:boolean}[]>([]);
	const [selectedRow, setSelectedRow] = React.useState({id:0});
	const [filters, setFilters] = React.useState({id:0, ataCode:'', title:'', acFamily:''});
	const [showFilters, setShowFilters] = React.useState(false);
	const [sort, setSort] = React.useState ({lable:'id', order:'asc'});
	const [acFamilies, setAcFamilies] = React.useState ([{}]);
	const [showEditor, setShowEditor] = React.useState (true);
	const [showIntervalFilters, setShowIntervalFilters] = React.useState(false)
	const [checkId, setCheckId] = React.useState(0);
	const [model, setModel] = React.useState({});
	const [threatments, setThreatments] = React.useState({});
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Запрос данных для таблицы
	const fetchData = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas`, 
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {
			const result = await response.json();
			setData(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	//Запрос списка AC Families
	const fetchFamilies = async () => {
		const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftFamilies?$select=acfamily`,
		{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
		if (response.ok) {	
			const result = await response.json();
			setAcFamilies(result.value);
		} else {
			setResponseStatus({...responseStatus, errorStatus:response.status});
		}
	};

	React.useEffect(() => {
		fetchData();
		fetchFamilies();
	}, []);

	//Выбор строки
	const handleRowSelection = (row) => {
		if(row.id === selectedRow.id){
			setSelectedRow({id:0});
		} else {
			setSelectedRow(row);
		}  
	};
	
	//Фильтрация данных
	React.useEffect(() => {
		let filtered = data;
		for (const key in filters) {
			if(filters[key] != ''){
				filtered = filtered.filter(row => row[key]?.toString().toLowerCase().includes(filters[key].toString().toLowerCase()))
			}
		}
        setFilteredData(filtered);
    }, [data, filters]);

	//Сброс фильтров
	const refreshFilters = () => {
		setFilters({id:0, ataCode:'', title:'', acFamily:''});
	}

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};

	//Сортировка данных
	const handleSort = (property, value) => {
		let filtered = filteredData
		if (property === 'id'){
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property] - b[property] : b[property]- a[property]);
		} else {
			filtered = filtered.sort((a, b) => value === 'asc' ? a[property]?.localeCompare(b[property]) : b[property]?.localeCompare(a[property]));
		}
		setFilteredData(filtered)
		setSort({lable:property, order:value})
	};

	//Проверка наличия фильтров
	const checkFilters = () => {
		const initialState = {id: 0, ataCode: '', title: '', acFamily: '' };
		return Object.keys(initialState).every(key => filters[key] === initialState[key]);
	}

	//Открытие и закрытие Editore
	const toggleEditore = () => {
		setShowEditor(prevState => !prevState);
	}
	
	//Создание Excel отчёта
	const createExcel = () => {
		return fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/Atas/Xlsx()?$filter=contains(atacode, '${filters.ataCode}') and contains(title, '${filters.title}') and contains(acfamily, '${filters.acFamily}')`, {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/vnd.ms-excel'
			},
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				setResponseStatus({...responseStatus, errorStatus:response.status});
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'ATA.xlsx'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
		});
	};

	//Создание PDF отчёта
	{/*const createReport = () => {
		setReportLoading(true);
		return fetch(`${URL}/reports/Reports`, {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
			},
			body:JSON.stringify({
				"templateName": "Templates/PostgreSQL/Admin/UserRegistrationList",
				"isExportAsPicture": false,
				"parameters": [
					{
						"name": 'userId',
						"value": `${filters.login}`
					},
					{
						"name": 'name',
						"value": `${filters.name}`
					},
					{
						"name": 'code',
						"value": `${filters.code}`
					},
					{
						"name": 'userGroup',
						"value": `${filters.userGroup}`
					},
					{
						"name": 'licence',
						"value": `${filters.licenceNum}`
					},
					{
						"name": 'skill',
						"value": `${filters.skill}`
					},
					{
						"name": 'ac',
						"value": ``
					},
					{
						"name": 'engCategories',
						"value": ``
					},
					{
						"name": 'secGroup',
						"value": `${filters.securityGroup}`
					},
					{
						"name": 'active',
						"value": `${filters.activate}`
					},
					{
						"name": 'storeDbName',
						"value": `Store`
					},
				]
			})
		})
		.then((response) => {
			if (response.ok) {
				return response.blob();
			} else {
				errors(response.status);
				setReportLoading(false);
			}
		})
		.then((blob) => {
			if (blob) {
				const url = window.URL.createObjectURL(blob); 
				const link = document.createElement('a'); 
				link.href = url;
				link.setAttribute('download', 'UserRegistrationList.pdf'); 
				document.body.appendChild(link); 
				link.click();
				link.remove(); 
				window.URL.revokeObjectURL(url);

				setReportLoading(false);
			} else {
				setReportLoading(false);
			}
		})
		.catch((error) => {
			console.error('Ошибка при получении файла:', error);
			setReportLoading(false);
		});
	};
*/}

	//Изменение ширины AddEditForm
	const [isDragging, setIsDragging] = React.useState(false);
	const [defWidth, setDefWidth] = React.useState(600)
  	const [addEditFormWidth, setAddEditFormWidth] = React.useState(defWidth);
	const [startPosition, setStartPosition] = React.useState({ x: 0});

	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({ x: e.clientX});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		const deltaX = e.clientX - startPosition.x;
		setAddEditFormWidth(defWidth-deltaX)
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
		setDefWidth(addEditFormWidth)
	};
	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				id='main'
				onMouseMove={handleMouseMove}
				onMouseUp={handleMouseUp}
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:showEditor?`auto 10px ${addEditFormWidth}px`:'100%',
				}}
			>
				<Box
					id='tableArea'
					sx={{
						position:'relative',
					}}
				>
					<Typography 
						id='tableAreaTitle'
						sx={{ 
							position:'absolute',
							fontSize:'16px',
							fontWeight:'bold',
							color:'#000078',
							fontFamily:'Arial, sans-serif',
							left:16
						}}
					>
						Model's Maintenance Checks:
					</Typography>
					<Sheet
						id='tablesSheet'
						variant="outlined"
						sx={{
							position:'absolute',
							width: `calc(100% - 32px)`,
							height:`calc(100% - 32px)`,
							borderRadius: '0px',
							flexShrink: 1,
							overflow: 'auto',
							border: '1px solid black',
							bgcolor:'white',
							mx:'16px',
							mt:'22px',
							mb:'10px'
						}}
					>
						<AmpModelTree 
							acFamily={filters.acFamily}
							ampId={5} 
							setCheckId={setCheckId}		
							setModel={setModel}	
							setThreatments={setThreatments}				
						/>
					</Sheet>
				</Box>
				<Box
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					sx={{
						cursor:'ew-resize',
						position:'relative',
					}}
				>
					<Divider 
						onMouseDown={handleMouseDown}
						onMouseMove={handleMouseMove}
						onMouseUp={handleMouseUp}
						orientation="vertical" 
						sx={{
							cursor:'ew-resize',
							position:'absolute',
							height:'100%'
						}}
					/>
				</Box>
				<Box
					id='editFormArea'
					sx={{
						display:showEditor?'flex':'none',
						position:'relative',
					}}
				>
					<AddEditeForm
						URL={props.URL}
						selectedRow={model} 
						selectedThreatments={threatments}
						reloadTable={fetchData}
						setResponseStatus={setResponseStatus}
						acFamilies={acFamilies}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}
