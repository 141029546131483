import * as React from 'react';

import Box from '@mui/joy/Box';
import Grid from '@mui/joy/Grid';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import Tooltip from '@mui/joy/Tooltip';
import Checkbox from '@mui/joy/Checkbox';
import Textarea from '@mui/joy/Textarea';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import FormControl from '@mui/joy/FormControl';
import Autocomplete from '@mui/joy/Autocomplete';

import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import Authentication from './Authentication.tsx';

export default function AddEditeForm(props) {
	//Стили
	const inputStyle = { borderRadius: 0, px:1, mx: 1, height: 30, minHeight: 0, border:'1px solid black', color:'#000084', fontWeight:'bold', fontSize:'14px' };
	const labelStyle = { mx: 1, my: 0, p: 0, fontSize:'16px'};
	const formControlStyle = { my: 1 };

	const [editedData, setEditedData] = React.useState({});
	const [selectedOperator, setSelectedOperator] = React.useState();
	const [operatorsList, setOperatorsList] = React.useState([]);
	const [meta, setMeta] = React.useState([{name:'',max:0}]);

	const [openAuthForm, setOpenAuthForm] = React.useState(false)

	React.useEffect(() => {
		setEditedData(props.selectedRow);
	}, [props.selectedRow]);

	//Запрос ограничений для полей
	React.useEffect(() => {
		const fetchMeta = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations/Meta()`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setMeta(result);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchMeta();
	}, []);

	React.useEffect(() => {
		const fetchOperatorsList = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/UsersFleets?$filter=operatorId eq ${selectedOperator.id} and (contains(aircraftFamily, '${props.selectedRow.acFamily}') or contains(aircraftFamily, 'ALL')) and activate eq 'Y' and user ne null`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setOperatorsList(result.value)
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		if(selectedOperator){
			fetchOperatorsList();	
		}
		
	}, [selectedOperator]);

	React.useEffect(() => {
		const fetchOperatorId = async () => {
			const response = await fetch(`${props.URL}${process.env.REACT_APP_API_ADMIN}/OperatorRegistrations?$filter=icao eq '${props.selectedRow?.operatorCodeIcao?.split('/')[0]}'&$select=id`, 
			{ headers: {'Authorization': `Bearer ${localStorage.getItem('asid')}`}});
			if (response.ok) {	
				const result = await response.json();
				setSelectedOperator(result.value[0]);
			} else {
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			}
		};
		fetchOperatorId();
	}, [props.selectedRow]);

	const handleAdd = async () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations`, 
			{
				method: 'POST',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					...editedData,
					engineType:editedData.acModel ? props.acTypes.find(data => data.model === editedData.acModel)?.engineType : '',
					apuType:editedData.acModel ? props.acTypes.find(data => data.model === editedData.acModel)?.apuType : ''
				})
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Add'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка создания группы:', error));
	}

	const handleUpdate = async () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations/${props.selectedRow.id}`, 
			{
				method: 'PATCH',
				headers: {
				'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				'Content-Type': 'application/json'
				},
				body: JSON.stringify(editedData)
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Update'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка обновления группы:', error));
	}

	const handleDelete = () => {
		fetch(
			`${props.URL}${process.env.REACT_APP_API_ADMIN}/AircraftRegistrations/${props.selectedRow.id}`, 
			{
				method: 'DELETE',
				headers: {
				  'Authorization': `Bearer ${localStorage.getItem('asid')}`,
				  'Content-Type': 'application/json'
				}
			}
		)
		.then((response) => {
			if (!response.ok){
				props.setResponseStatus({...props.responseStatus, errorStatus:response.status});
			} else {
				props.setResponseStatus({...props.responseStatus, successfulStatus:'Delete'});
			}
			props.reloadTable();
			return response.json();
		})
		.catch(error => console.error('Ошибка удаления группы:', error));
	}
	
	//Внесение изменений в data
	const handleChange = (property, value) => {
		setEditedData({ ...editedData, [property]: value });
	};

	const draftAMP = () => {
		setOpenAuthForm(true);
	}
	const activateAMP = () => {
		setOpenAuthForm(true);
	}
	

	return (
		<Box>
			{openAuthForm && (
				<Authentication setOpenAuthForm={setOpenAuthForm} selectedAMP={editedData} setResponseStatus={props.setResponseStatus} activeAMP={props.activeAMP} URL={props.URL}/>
			)}
			<Typography 
				sx={{
					fontSize:'18px',
					fontWeight:'bold',
					color:'#000078',
					fontFamily:'Arial, sans-serif',
					ml:1
				}}
			>
				Aircraft Maintenance Program Editor:
			</Typography>
			<Tooltip title='Add'>
				<IconButton
					variant='plain'
					disabled={true}
					onClick={handleAdd}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="/images/svg/add.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Update'>
				<IconButton 
					variant='plain'
					disabled={true}
					onClick={handleUpdate}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px' 
						src="/images/svg/save.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Tooltip title='Delete'>
				<IconButton 
					variant='plain'
					disabled={true}
					onClick={handleDelete}
					sx={{
						m:0,
						'&:disabled img': {
							opacity: 0.5,
						}
					}}
				>
					<img 
						width='35px' 
						height='35px'  
						src="/images/svg/trashcan.svg" 
						alt="" 
					/>
				</IconButton>
			</Tooltip>
			<Box
				sx={{
					position:'absolute',
					height:`calc(100% - 79px)`,
					overflow:'auto'
				}}
			>
				<Grid container spacing={0} sx={{ flexGrow: 1 }}>
					<Grid xs={8}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>AMP Revision:</FormLabel>
							<Input  
								sx={inputStyle} 
								value={editedData?.revision ?? ''}
								onChange={(event) => handleChange('revision', event.target.value)}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Revision Date:</FormLabel>
							<Input
								type="date"
								sx={inputStyle}
								value={editedData?.revisionDate?.slice(0, 10) ?? ''}  
								onChange={(event) => handleChange('revisionDate', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={3}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Create By:</FormLabel>
							<Autocomplete
								disableClearable
								options={operatorsList.map(item => item.user)}
								value={{ label: editedData?.createUserId || '' }}
								sx={{
									...inputStyle,
									backgroundColor:'#c6ffff',
								}}
								onChange={(event, value) => handleChange('createUserId', value || '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={5}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Name:</FormLabel>
							<Input 
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={operatorsList.find(item => item.user === editedData?.createUserId)?.userName || ''}
							/>
						</FormControl>
					</Grid>
					<Grid xs={4}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Creation Date:</FormLabel>
							<Input
								type="date"
								sx={inputStyle}
								value={editedData?.revisionDate?.slice(0, 10) ?? ''}  
								onChange={(event) => handleChange('revisionDate', event.target.value)}
							/>
						</FormControl>
					</Grid>

					<Grid xs={5}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>AC Family:</FormLabel>
							<Autocomplete
								disableClearable
								disabled
								options={[props.selectedACFamil]}
								value={{ label: props.selectedACFamily || '' }}
								sx={{
									...inputStyle,
									backgroundColor:'#c6ffff',
								}}
								onChange={(event, value) => handleChange('acModel', value ? value.label : '')}
							/>
						</FormControl>
					</Grid>
					<Grid xs={7}></Grid>
				
					<Grid xs={3}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Code ICAO:</FormLabel>
							<Input 
								size="sm" 
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={props.selectedRow?.operatorCodeIcao || ''}
							/>
						</FormControl>
					</Grid>
					<Grid xs={9}>
						<FormControl sx={formControlStyle} required>
							<FormLabel sx={labelStyle}>Operator Name:</FormLabel>
							<Input 
								size="sm" 
								disabled
								sx={{
									...inputStyle, 
									'--variant-outlinedDisabledColor':'black',
									'--variant-outlinedDisabledBorder':'black',
									bgcolor:'#d6d3ce',
								}} 
								value={props.selectedRow?.operatorName || ''}
							/>
						</FormControl>
					</Grid>

					<Grid xs={12}>
						<FormControl sx={formControlStyle}>
							<FormLabel sx={labelStyle}>Remarks:</FormLabel>
							<Textarea 
								maxRows={5}
								sx={{
									...inputStyle,
									color:'#000078',
									height:'150px',
									lineHeight:1.2,
									
								}}
								value={editedData?.remarks ?? ''}
								onChange={(event) => handleChange('remarks', event.target.value)}
							/>
						</FormControl>
					</Grid>
					<Grid xs={12}>
						<Box
							sx={{
								height:100,
								mx:1,
								alignItems:'center',
								justifyContent:'space-between',
								border:'1px solid gray'
							}}
						>
							<Box
								sx={{
									height:100,
									display:props.selectedRow?.id !== 0 && props.selectedRow?.draft === 'N' ? 'flex':'none',
									alignItems:'center',
									justifyContent:'space-between',
								}}
							>
								<Checkbox 
									label="Active AMP:"
									variant="outlined"
									color="neutral"
									checked={true}
									sx={{
										fontSize:'16px',
										fontWeight:'bold',
										'--Checkbox-size':'15px',
										gap:0.4,
										ml:1,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
								/>
								<Typography
									sx={{
										color:'#89120b',
										fontSize:'16px',
										fontWeight:'bold',
									}}
								>
									Active AMP is Selected !
								</Typography>
								<Button
									variant="outlined"
									color="neutral"
									onClick = {draftAMP}
									sx={{
										borderRadius: '0px',
										fontSize: '14px',
										fontWeight:100,
										backgroundColor:'#dedff7',
										border:'2px outset white',
										height:56,
										width:90,
										display:'flex',
										flexDirection:'column',
										mr:1
									}}
								>
									<img 
										width='27px' 
										height='27px' 
										src="/images/OUTLOOK7.ico" 
										alt="" 
									/>
									to Draft
								</Button>
							</Box>
							<Box
								sx={{
									height:100,
									display:props.selectedRow?.id !== 0 && props.selectedRow?.draft === 'Y' ? 'flex':'none',
									alignItems:'center',
									justifyContent:'space-between',
								}}
							>
								<Checkbox 
									label="Draft Copy:"
									variant="outlined"
									color="neutral"
									checked={true}
									sx={{
										fontSize:'16px',
										fontWeight:'bold',
										'--Checkbox-size':'15px',
										gap:0.4,
										ml:1,
										'& .css-r4cpcz-JoyCheckbox-checkbox': {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
										'& .css-n110n' : {
											borderRadius:0,
											'--joy-palette-neutral-outlinedBorder':'black',
										},
									}}
								/>
								<Typography
									sx={{
										color:'#89120b',
										fontSize:'16px',
										fontWeight:'bold',
									}}
								>
									Draft Copy of AMP is Selected !
								</Typography>
								<Button
									variant="outlined"
									color="neutral"
									onClick = {activateAMP}
									sx={{
										borderRadius: '0px',
										fontSize: '14px',
										fontWeight:100,
										backgroundColor:'#dedff7',
										border:'2px outset white',
										height:56,
										width:90,
										display:'flex',
										flexDirection:'column',
										mr:1
									}}
								>
									<img 
										width='27px' 
										height='27px' 
										src="/images/OUTLOOK7.ico" 
										alt="" 
									/>
									Activate
								</Button>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
}