import * as React from 'react';

//Компоненты библиотеки @mui/joy
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Input from '@mui/joy/Input';
import Tooltip from '@mui/joy/Tooltip';
import Divider from '@mui/joy/Divider';
import Checkbox from '@mui/joy/Checkbox';
import FormLabel from '@mui/joy/FormLabel';
import Typography from '@mui/joy/Typography';
import IconButton from '@mui/joy/IconButton';
import FormControl from '@mui/joy/FormControl';

//Компоненты 
import Requirements from './Requirements.tsx'
//import MaintenanceAddEditeForm from './PartMaintenancePlanEditor.tsx';

import ErrorSuccessfulResponse from '../../../Components/ErrorSuccessfullResponse.tsx';

//import AmpPartEffictivityTree from './TreeViews/AmpPartEffectivityTree.tsx';
//import AmpPlanMaintenanceTree from './TreeViews/AmpPlanMaintenanceTree.tsx';
import AmpPositionsTree from './TreeViews/AmpPositionsTree.tsx';

export default function Pos_Struct(props) {
	//Стили
	const inputStyle = {borderRadius:'0px', minHeight:'0px', height:'26px', border:'1px solid black',color:'#000078', fontWeight:'bold',fontSize:'14px', mb:1, minWidth:0,width:'100%'}
	const lableStyle = {m:0, fontFamily:'Arial, sans-serif', fontSize:'14px',alignSelf:'center',lineHeight:1, color:'black'};

	const [filters, setFilters] = React.useState<{subAssy:boolean, ipcPos:string, partEff:string}>();
	const [positionId, setPositionId] = React.useState({});
	
	const [responseStatus, setResponseStatus] = React.useState({errorStatus:0, successfulStatus:''})

	//Изменение фильтров
	const handleChange = (property, value) => {
		setFilters({ ...filters, [property]: value });
	};
	
	//#region Изменение высоты таблицы
	const [isDragging, setIsDragging] = React.useState(false);
	const [defHeight, setDefHeight] = React.useState(300)
	const [tableAreaHeight, setTableAreaHeight] = React.useState(defHeight);
	const [startPosition, setStartPosition] = React.useState({ y: 0});

	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartPosition({ y: e.clientY});
	};
	
	const handleMouseMove = (e) => {
		if (!isDragging) return;
		const deltaY = e.clientY - startPosition.y;
		setTableAreaHeight(defHeight+deltaY)
	};
	
	const handleMouseUp = () => {
		setIsDragging(false);
		setDefHeight(tableAreaHeight)
	};
	//#endregion

	return (
		<React.Fragment>
			<ErrorSuccessfulResponse responseStatus={responseStatus} setResponseStatus={setResponseStatus} />
			<Box
				id='main'
				sx={{
					position:'relative',
					height:'100%',
					display:'grid',
					gridTemplateColumns:'50% 50%',
				}}
			>
				<Box
					id='tablesArea'
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					sx={{
						display: 'grid',
						gridTemplateRows:`${tableAreaHeight}px 10px auto`,
						position:'relative'
					}}
				>
					<Box
						id='topArea'
						sx={{
							position:'relative',
						}}
					>
						<Typography 
							id='topAreaTitle'
							sx={{ 
								position:'absolute',
								fontSize:'16px',
								fontWeight:'bold',
								color:'#000078',
								fontFamily:'Arial, sans-serif',
								left:16
							}}
						>
							AMP IPC Positions Structure:
						</Typography>	
						<Box
							id='buttons'
							sx={{
								display:'flex',
								alignItems:'center',
								justifyContent:'center',
								position:'absolute',
								right:16,
								height:40,
								width:`100%`,
							}}
						>
							<Box
								className="SearchAndFilters-tabletUp"
								sx={{
									width:'100%',
									display:'grid',
									gridTemplateColumns:'auto 100px repeat(2, 20%)',
									gap:1,
									mt:1.5
								}}
							>
								<Box
									sx={{
										display: 'flex',
										alignItems:'flex-end',
										justifyContent:'center',
										gridColumn:2,
										mb:1
									}}
								>
									<Typography sx={{...lableStyle,alignSelf:'flex-end'}}>
										Sub-Assy:
									</Typography>
									<Tooltip title='Apply Filters to Sub-Assy also'>
										<Checkbox
											checked={filters?.subAssy}
											onChange={(event) => handleChange('subAssy', event.target.checked)}
											variant='outlined'
											color='neutral'
											sx={{
												ml:1,
												'& span': {
													borderRadius: '0px',
													borderWidth:'1px',
													borderColor:'black',
													color:'black'
												}, 
											}}
										/>
									</Tooltip>
								</Box>
								<Tooltip title='Filter IPC Position'>
									<FormControl>
										<Input
											value={filters?.ipcPos || ''}
											onChange={(event) => handleChange('ipcPos', event.target.value)}
											sx={inputStyle}
										/>
									</FormControl>
								</Tooltip>
								<Tooltip title='Filter Part Effectivity'>
									<FormControl>
										<Input
											value={filters?.partEff || ''}
											onChange={(event) => handleChange('partEff', event.target.value)}
											sx={inputStyle}
										/>
									</FormControl>
								</Tooltip>
							</Box>
						</Box>		
						<Sheet
							id='tablesSheet'
							variant="outlined"
							sx={{
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`calc(100% - 54px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
								mt:'44px',
								mb:'10px'
							}}
						>
							<AmpPositionsTree
								setPositionId={setPositionId}
								acFamily={props.selectedACFamily}
								ampId={props.selectedAmp?.id} 
								isSubassy={filters?.subAssy || false}
								partEff={filters?.partEff || ''}
								ipcPos={filters?.ipcPos || ''}
							/>
						</Sheet>
					</Box>
					<Box
						onMouseDown={handleMouseDown}
						onMouseMove={handleMouseMove}
						onMouseUp={handleMouseUp}
						sx={{
							cursor:'ns-resize',
							position:'relative',
						}}
					>
						<Divider 
							onMouseDown={handleMouseDown}
							onMouseMove={handleMouseMove}
							onMouseUp={handleMouseUp}
							orientation="horizontal"
							sx={{
								cursor:'ns-resize',
								position:'absolute',
								width:'100%'
							}}
						/>
					</Box>		
					<Box
						id='bottomArea'
						sx={{
							position:'relative',
						}}
					>
						<Typography 
							id='topAreaTitle'
							sx={{ 
								position:'absolute',
								fontSize:'16px',
								fontWeight:'bold',
								color:'#000078',
								fontFamily:'Arial, sans-serif',
								left:16
							}}
						>
							Relation between IPC Positions - MR:
						</Typography>	
						<Sheet
							id='tablesSheet'
							variant="outlined"
							sx={{
								position:'absolute',
								width: `calc(100% - 32px)`,
								height:`calc(100% - 34px)`,
								borderRadius: '0px',
								flexShrink: 1,
								overflow: 'auto',
								border: '1px solid black',
								bgcolor:'white',
								mx:'16px',
								mt:'24px',
								mb:'10px'
							}}
						>
							
						</Sheet>
					</Box>
				</Box>
				<Box
					id='requirements'
					sx={{
						display:'flex',
						position:'relative',
					}}
				>
					<Requirements
						URL={props.URL}
						selectedAmp={props.selectedAmp}
					/>
				</Box>
			</Box>
		</React.Fragment>
	);
}