import React, { useState, useEffect } from "react";
import buildQuery from "odata-query";
import Table from "@mui/joy/Table";
import Checkbox from "@mui/joy/Checkbox";
import CircularProgress from "@mui/joy/CircularProgress";
import Header from "../../Layout/Header";
import Application from "../../../Application";
import { CssVarsProvider } from "@mui/joy/styles";
import CheckBoxStyle from "../../../Theme/CheckBoxStyle.jsx";
import { toast, Zoom, ToastOptions} from "react-toastify";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import SearchIcon from "@mui/icons-material/Search";
import WestOutlinedIcon from "@mui/icons-material/WestOutlined";
import { Stack } from "@mui/material";
import Tooltip from "@mui/joy/Tooltip";
import IconButton from "@mui/joy/IconButton";
import Button from "@mui/joy/Button";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Input from "@mui/joy/Input";
import FormLabel from "@mui/joy/FormLabel";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FormControl from "@mui/joy/FormControl";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import "./Dto/Model.tsx"
import Parameter from "./Parameter.tsx";

export default function ReportTemplate({isOpen, onClose, onSelectCan, moduleName = "Destiny Herald", windowName = "", version = "", reportId = ""}) {
	const application = new Application();
	const baseUrl = application.getDestinyHeraldUrl();
	const endpointUrl = new URL("Templates", baseUrl);
	const headers = { "Authorization": "Bearer " + localStorage.getItem("asid"), "Content-Type": "application/json" };
	const [isShowEditor, setIsShowEditor] = useState<boolean>(true);
	const [isShowSpinner, setIsShowSpinner] = useState<number>(0);
	const [items, setItems] = useState<Array<ReportTemplateDto>>([]);
	const [selectedItem, setSelectedItem] = useState<ReportTemplateDto | null>(null);
	const [editableItem, setEditableItem] = useState<ReportTemplateDto | null>(null);
	const [changes, setChanges] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>("");
	const [timerId, setTimerId] = useState<any>(null);
	const [permission, setPermission] = useState([]);
	const [isShowParameterWindow, setIsShowParameterWindow] = useState<boolean>(false);
	const [numberParameters, setNumberParameters] = useState<number>(0);

	var clickСheckbox = false;
	
	const toastOptions: ToastOptions = {
		position: "bottom-right",
		autoClose: 5000,
		hideProgressBar: false,
		theme: "light",
		transition: Zoom
	};

	const styleTableHeader = {
		color: "white",
		fontWeight: "normal",
		fontSize: "15px",
		padding: "6.5pt",
		borderRadius: "0",
		width: "auto",
		whiteSpace: "nowrap",
	}

	const styleTable = {
		"--TableCell-headBackground": "#3B3D40",
		"--TableCell-paddingY": "3pt",
		"--TableCell-height": "16pt",
		"--TableCell-paddingX": ".5rem",
		"--TableCell-borderColor": "#C0BFBF",
		"--joy-palette-background-level2": "#EFEFEF",
		"--Table-headerUnderlineThickness": "1px",
		"--TableRow-hoverBackground": "#C3D0EA",
		tableLayout: "auto",
	};

	const styleToolbarButton = {
		color: "#6B6B6B",
		"&:hover": {
			color: "#276CF2",
			background: "none"
		}
	}

	useEffect(() => {
		async function onMount() {
			let items = await get();
				
			if (reportId) {
				setItems(await selectItems(items));
			}
			else {
				setItems(items);
			}

			setPermission(await getPermission("Destiny Herald", "Report Templates"));
		}
		
		onMount();
	}, []);

	async function getNumberParameters(templateId: string | undefined) : Promise<number> {
		if (!templateId) return 0;
		
		try {	
			setIsShowSpinner(value => value + 1);
	
			let url = endpointUrl + "/" + templateId +
				"/?&expand=parameters(count=true;$top=0)&$select=parameters";

			let response = await fetch(url, { method: "GET", headers: headers });
		
			if (response.status === 401) {
				Application.logout();
			}
	
			if (response.ok) {
				let answer = await response.json();
				return answer.value[0]["parameters@odata.count"];
			}
			else {
				let answer = await response.json();
					
				if (answer?.message) {
					toast.error(<div>Error<br/><hr/>{answer.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed get number of parameters.</div>, toastOptions);
				}
				return 0;
			}
		}
		catch (ex) {
			toast.error(<div>Error<br/><hr/>Failed get number of parameters.</div>, toastOptions);
			return 0;
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function selectItems(items: Array<ReportTemplateDto>) : Promise<Array<ReportTemplateDto>> {
		let selectedItems = await getSelectedItems();

		for (let i = 0; i < selectedItems.length; i++) {
			let item = items.find(x => x.id === selectedItems[i].templateId);
			if (item) {
				item.isSelected = true;
				item.position = selectedItems[i].position;
			}
		}

		return items;
	}

	async function getSelectedItems() : Promise<Array<ReprotHasTemplateDto>> {
		try {
			setIsShowSpinner(value => value + 1);
			
			let url = new URL("Reports", baseUrl);

			let query = "/" + reportId + "/Templates/";

			let response = await fetch(url + query,
				{ method: "GET", headers: headers });

			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}
				
				let answer = await response.json();
				
				toast.error(<div>Error<br/><hr/>{answer?.message}</div>, toastOptions);
				return [];
			}
				
			let content = await response.json();
			return content.value;
		}
		catch {
			toast.error(<div>Error<br/><hr/>Error getting linked templates.</div>, toastOptions);
			return [];
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	function closeCommand() : void {
		onClose();
	}

	async function saveSelectedCommand() : Promise<void> {
		let selectedItems: Array<{id: string, position: number}> = [];

		items.map((item) => {
			if (item.isSelected) {
				selectedItems.push({
					id: item.id,
					position: item.position
				});
			}
		});

		await updateTemplates(selectedItems);
	}

	async function updateTemplates(selectedItems: Array<{id: string, position: number}>) {
		try {
			setIsShowSpinner(value => value + 1);

			var url = new URL("reports", baseUrl) + "/" + reportId + "/templates";
			
			let data = {
				templates: selectedItems
			}

			let response = await fetch(url, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(data)
			});

			if (response.status === 401) {
				Application.logout();
			}

			if (response.ok) {
				toast.success("The list of recipients has been saved.", toastOptions);
			}
			else {
				let content = await response.json();
				
				if (content?.message) {
					toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed to save list of recipients.<br/>Code: {response.status}</div>, toastOptions);
				}
			}
		}
		catch(e) {
			toast.error(<div>Error<br/><hr/>Failed to save list of recipients.</div>, toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	function saveSelectedCommandCan() : boolean {
		return onSelectCan();
	}

	async function getPermission(moduleName: string, windowName: string) {
		try {	
			setIsShowSpinner(value => value + 1);
	
			const filter = [
				{ module: moduleName },
				{ window: windowName }
			];
				
			let query = buildQuery({filter});
	
			let url = application.getAdminUrl() + "self/permissions" + query;
	
			let response = await fetch(url, { method: "GET", headers: headers });
	
			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}
	
				let answer = await response.json();
					
				toast.error(<div>Failed get permissions<br/><hr/>{answer?.message}</div>, toastOptions);
				return "";
			}
	
			let content = await response.json();
	
			if (content.value.length > 0) {
				return content.value[0].access;
			}
			else {
				toast.error(<div>Error<br/><hr/>Missing permissions.</div>, toastOptions);
				return "";
			}
		}
		catch (ex) {
			toast.error(<div>Error<br/><hr/>Failed get permissions.</div>, toastOptions);
			return "";
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function get(value: string = "") : Promise<Array<ReportTemplateDto>> {
		try {
			setIsShowSpinner(value => value + 1);
			
			let query: string = "";

			if (value) {
				const filter = `startswith(fileName, '${value}')`;

				query = buildQuery({filter});
			}

			let response = await fetch(endpointUrl + query,
				{ method: "GET", headers: headers });

			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}
				
				let answer = await response.json();
				
				toast.error(<div>Error<br/><hr/>{answer?.message}</div>, toastOptions);
				return [];
			}
				
			let content = await response.json();
			return content.value;
		}
		catch {
			toast.error(<div>Error<br/><hr/>Failed get templates</div>, toastOptions);
			return [];
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function onRowClick(evernt, item: ReportTemplateDto) {
		if (clickСheckbox) {
			clickСheckbox = false;
			return;
		}
		
		let value;

		if (selectedItem?.id === item.id) {
			value = null;
		}
		else {
			value = item;
		}

		setSelectedItem(value);
		setEditableItem(value === false ? value : {...value});
		setChanges(false);
		setNumberParameters(await getNumberParameters(value?.id));
	}

	function onCheckboxClick(itemId: string) {
		clickСheckbox = true;
		
		items.map((item) => {
			if (item.id === itemId) {
				item.isSelected = !item.isSelected;
			}
		});

		setItems([...items]);
	}
	
	function onEditItem(name: string, value: any) {
		let modifiedItem = {...editableItem, [name]: value} as ReportTemplateDto;

		setChanges(modifiedItem.fileName != selectedItem?.fileName);

		setEditableItem(modifiedItem);
	}

	function editPosition(value: number) {
		onEditItem("position", value);

		if (selectedItem) {
			let item = items.find(x => x.id === selectedItem.id);
			if (item) {
				item.position = value;
			}
		}
	}

	function getRowColor(index: number, item: ReportTemplateDto) {
		if (selectedItem?.id === item.id) return "#C8C8C8";
		if (index % 2 != 0) return "#EFEFEF";
		return "white";
	}

	async function update() {
		try {
			setIsShowSpinner(value => value + 1);

			var url = endpointUrl.href + "/" + selectedItem?.id
			
			let data = {...editableItem};
			delete data["isSelected"];
			delete data["position"];

			let response = await fetch(url, {
				method: "PATCH",
				headers: headers,
				body: JSON.stringify(data)
			});

			if (response.status === 401) {
				Application.logout();
			}

			if (response.ok) {
				toast.success("Changes saved.", toastOptions);

				//#region Поиск элемента и смена состояния
						
				let item = items.find(i => i.id === editableItem?.id);
				
				if (item) {
					if (item.fileName != editableItem?.fileName) item.fileName = editableItem?.fileName ?? "";
				}

				//#endregion

				setChanges(false);
			}
			else {
				let content = await response.json();
				
				if (content?.message) {
					toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed to save changes.<br/>Code: {response.status}</div>, toastOptions);
				}
			}
		}
		catch(e) {
			toast.error(<div>Error<br/><hr/>Failed to save changes.</div>, toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function add() {
		try {
			setIsShowSpinner(value => value + 1);

			let newItem = {...editableItem};
			delete newItem["id"];
			delete newItem["isSelected"];

			let response = await fetch(endpointUrl, {
				method: "POST",
				headers: headers,
				body: JSON.stringify(newItem)
			});

			if (response.status === 401) {
				Application.logout();
			}
			if (response.ok) {
				toast.success("Template added.", toastOptions);

				setSelectedItem(null);
				setEditableItem(null);
				setItems(await get());
				
				setChanges(false);
			}
			else {
				let content = await response.json();
				
				if (content?.message) {
					toast.error(<div>Error<br/><hr/>{content.message}</div>, toastOptions);
				}
				else {
					toast.error(<div>Error<br/><hr/>Failed to add template.<br/>Code: {response.status}</div>, toastOptions);
				}
			}
		}
		catch(e) {
			toast.error(<div>Error<br/><hr/>Failed to add template.</div>, toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	async function remove() {
		try {
			setIsShowSpinner(value => value + 1);
			
			var url = endpointUrl.href + "/" + selectedItem?.id

			let response = await fetch(url,
				{ method: "DELETE", headers: headers });
			
			if (!response.ok) {
				if (response.status === 401) {
					Application.logout();
				}
				
				let answer = await response.json();
				
				toast.error(<div>Error<br/><hr/>{answer.Message}</div>, toastOptions);
				return;
			}

			let newList =
				items.filter(i => i !== selectedItem);
			
			setSelectedItem(null);
			setEditableItem(null);
			
			setItems(newList);
		}
		catch(e) {
			toast.error("Failed to delete.", toastOptions);
		}
		finally {
			setIsShowSpinner(value => value - 1);
		}
	}

	function debounce (fn: () => void, d: number) {
		if (timerId) clearTimeout(timerId);	
		setTimerId(setTimeout(fn, d));
	}

	function onChangeSearchString(e) {
		setSearchString(e.target.value);
		
		debounce(async () => {			
			setItems(await get(e.target.value));
		}, 500);
	}

	function addCan() : boolean {
		return true;
	}

	function onSelectParameters(selectedIds: Array<string>) {

	}

	return(
		<Modal open={isOpen} onClose={closeCommand}>
			<ModalDialog layout="fullscreen" sx={{padding: "0", margin: "0", whiteSpace: "none", gap: "0"}}>
				<div style={{height: "100%", display: "grid", transitionDuration: "300ms", gridTemplateColumns: isShowEditor ? "1fr 25%" : "1fr 0", gridTemplateRows: "auto auto 1fr auto auto", gridTemplateAreas: "'header header' 'toolbar editor' 'table editor' 'bottom-toolbar editor' 'footer footer'"}}>
					{/* Header */}
					<div style={{gridArea: "header"}}>
						<Header title={moduleName} version={version} />
					</div>
					
					{/* Toolbar */}
					<div style={{gridArea: "toolbar", padding: ".3rem 1.3rem .3rem .5rem", background: "#FBFCFE", display: "flex", flexDirection: "row", flexWrap: "nowrap", justifyContent: "space-between"}}>
						<Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start"}}>
							<Tooltip title="BACK" variant="solid" placement="top">
								<IconButton onClick={closeCommand} variant="outlined" sx={styleToolbarButton}>
									<WestOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
								</IconButton>
							</Tooltip>

							<h3 style={{color: "#6B6B6B", margin: ".4rem 0 0 .5rem"}}>{windowName}</h3>
						</Stack>
						
						<Stack direction="row" spacing={1} sx={{ justifyContent: "flex-end"}}>
							<Input value={searchString} onChange={onChangeSearchString} endDecorator={<SearchIcon />} sx={{color: "#276CF2", fontWeight: "600"}} />
								
							{
								isShowEditor == false &&

								<Tooltip title="SHOW EDITOR" variant="solid" placement="top">
									<IconButton onClick={() => setIsShowEditor(value => !value)} variant="outlined" sx={styleToolbarButton}>
										<EditNoteRoundedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>
							}
						</Stack>
					</div>

					{/* Table */}
					<div style={{overflow: "auto", gridArea: "table", whiteSpace: "nowrap"}}>
						<Table borderAxis="both" stripe="even" size="sm" stickyHeader sx={{...styleTable, overflow: "auto"}}>
							<thead>
								<tr>
									<th style={{...styleTableHeader, width: "42px"}}></th>
									<th style={styleTableHeader}>File Name:</th>
								</tr>
							</thead>
							<tbody>
								{items.map((row, index) => (
									<tr key={row.id} onClick={(event) => onRowClick(event, row)} style={{background: getRowColor(index, row)}}>
										<td style={{textAlign: "center"}}>
											<CssVarsProvider theme={CheckBoxStyle}>
												<Checkbox checked={row.isSelected ?? false} onClick={() => onCheckboxClick(row.id)} />
											</CssVarsProvider>
										</td>
										<td>{row.fileName}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>

					{/* Bottom Toolbar */}
					<div style={{gridArea: "bottom-toolbar", borderTop: "1px solid #C0BFBF"}}>
						<Stack direction="row" spacing={20} sx={{justifyContent: "center", margin: "1rem 0 .8rem 0"}}>
							<Button sx={{width: "8rem"}} onClick={() => saveSelectedCommand()} disabled={!saveSelectedCommandCan()}>Save</Button>
							<Button sx={{width: "8rem"}} onClick={closeCommand}>Clsoe</Button>
						</Stack>
					</div>
						
					{/* Editor */}
					<div style={{gridArea: "editor", overflow: "auto", boxShadow: "-7px 0px 8px -6px rgba(17, 17, 17, 0.22)"}}>
						{/* Toolbar */}
						<div style={{padding: ".3rem 1.3rem .3rem .5rem", gridArea: "toolbar", display: "grid", gridTemplateColumns: "auto 1fr"}}>
							<Stack direction="row" spacing={1} sx={{ justifyContent: "flex-start"}}>
								<Tooltip title="ADD" variant="solid" placement="top">
									<IconButton onClick={add} disabled={!addCan()} variant="plain" sx={styleToolbarButton}>
										<AddCircleOutlineOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>

								<Tooltip title="UPDATE" variant="solid" placement="top">
									<IconButton onClick={update} variant="plain" sx={styleToolbarButton} disabled={!(changes && selectedItem != null)}>
										<SaveOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>

								<Tooltip title="RESET" variant="solid" placement="top">
									<IconButton onClick={() => {setSelectedItem(null); setEditableItem(null)}} disabled={!editableItem} variant="plain" sx={styleToolbarButton}>
										<RestartAltOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>

								<Tooltip title="DELETE" variant="solid" placement="top">
									<IconButton onClick={remove} variant="plain" sx={styleToolbarButton} disabled={!selectedItem}>
										<DeleteForeverOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>
							</Stack>

							<div style={{display: "flex", justifyContent: "end"}}>
								<Tooltip title="HIDE EDITOR" placement="top">
									<IconButton onClick={() => setIsShowEditor(value => value = !value)} sx={styleToolbarButton}>
										<CloseOutlinedIcon sx={{width: "1.7rem", height: "1.7rem"}} />
									</IconButton>
								</Tooltip>
							</div>
						</div>
					
						{/* Form */}
						<div style={{ margin: "12px 9px" }}>
							<FormControl required>
								<FormLabel>File Name:</FormLabel>
								<Input placeholder="File Name" value={editableItem?.fileName ?? ""} onChange={e => onEditItem("fileName", e.target.value)} />
							</FormControl>

							<FormControl required sx={{marginTop: "15px", display: saveSelectedCommandCan() ? "block" : "none"}}>
								<FormLabel>Number Position:</FormLabel>
								<Input placeholder="Position" type="number" value={editableItem?.position ?? 1} onChange={e => editPosition(Number(e.target.value))} />
							</FormControl>

							<Button variant="outlined" color="neutral" startDecorator={numberParameters > 0 ? <CheckOutlinedIcon /> : null} sx={{height: "38px", marginTop: "15px", width: "100%", color: "#6B6B6B"}} onClick={() => setIsShowParameterWindow(value => !value)}>Parameters</Button>
						</div>
					</div>

					{/* Footer */}
					<div style={{gridArea: "footer", background: "#3B3D40", color: "white", fontSize: "10pt", padding: ".2rem 1rem .3rem 1rem", display: "flex", justifyContent: "space-between"}}>
						<div>User: {localStorage.getItem("login")}</div>
						<div>Permission: {permission}</div>
					</div>
				</div>

				{
					isShowSpinner != 0 &&

					<div style={{position: "absolute", width: "100%", height: "100dvh", zIndex: 10}}>
						<CircularProgress color="primary" sx={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", "--CircularProgress-size": "100px", zIndex: "10"}}>
							<span style={{fontSize: "120%", color: "#3B3D40", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", textWrap: "nowrap"}}>Please wait ...</span>
						</CircularProgress>
					</div>
				}

				{
					isShowParameterWindow &&
													
					<Parameter
						moduleName={document.title}
						windowName="Parameters"
						version={version}
						isOpen={isShowParameterWindow}
						onClose={async () => {setNumberParameters(await getNumberParameters(selectedItem?.id)); setIsShowParameterWindow(value => !value)}}
						onSelect={onSelectParameters}
						onSelectCan={() => !!selectedItem}
						reportId={selectedItem?.id} />
				}
			</ModalDialog>
		</Modal>
	);
}