let currentURL = window.location.hostname;
let URL;

if (currentURL.includes('localhost')) {
  	URL = 'https://devpg.alaskar.tech/api';
} else {
  	URL = window.location.protocol + '//' + window.location.hostname + '/api';
} 

export default URL;
