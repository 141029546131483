import Box from "@mui/joy/Box";
import Menu from "@mui/joy/Menu";
import Link from "@mui/joy/Link";
import Avatar from "@mui/joy/Avatar";
import Dropdown from "@mui/joy/Dropdown";
import MenuItem from "@mui/joy/MenuItem";
import Typography from "@mui/joy/Typography";
import MenuButton from "@mui/joy/MenuButton";
import ListDivider from "@mui/joy/ListDivider";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import Application from "../../Application";
import PropTypes from 'prop-types';

/**
 * @param title - аголовок модуля.
 * @param version - версия модуля.
 * @param handleLogout - действие при нажатии кнопки Log Out. По умолчанию, из storage удадаяются: name, login, asid и осуществляется переход на страницу аутентификации.
 */
export default function Header({title= "", version = "", handleLogout = null}) {
	
	async function logout() {
		if (handleLogout) {
			handleLogout();
		}
		else {
			await logoutDefault();
		}
	}

	/**
	 * Процедуру можно переопределить, передав указатель на свою процедуру в свойстве {handleLogout}.
	 */
	async function logoutDefault() {
		try {
			const endpointUrl = new URL("self", new Application().getAdminUrl());

			const headerAuthorization = {
				"Authorization": "Bearer " + localStorage.getItem("asid")
			};

			let response = await fetch(endpointUrl,
				{ method: "DELETE", headers: headerAuthorization });

			if (!response.ok) {
				console.error("Request Error.");
			}
		}
		catch (error) {
			console.error("Logout Error.", error);
		}
		finally {
			Application.logout();
		}
	};

    return(
        <div style={{ 
			display: "flex",
			flexWrap: "wrap",
			alignItems: "center",
			background: "#FBFCFE",
			justifyContent: "space-between",
			padding: ".5rem 1rem 0 1rem",
			borderBottom: "1px solid #DDDFE2"}}>
			
			<div style={{flex: 1}}>
				<Link href="/" sx={{display: {xs: "none", md: "inline"}}}>
					<img width="130" src="images/logo.svg" alt="alaskar-logo" style={{margin: "-2px 0"}} />
				</Link>

				<Link href="/" sx={{display: {xs: "inline", md: "none"}}}>
					<img style={{marginTop: "-8px"}} width="50" src="images/logo_shield.svg" alt="alaskar-logo" />
				</Link>
			</div>
	
			<div style={{flex: 1, textAlign: "center", textWrap: "nowrap", position: "relative", marginTop: "-0.5rem"}}>
				<h2 style={{margin: 0, color: "#6B6B6B", display: "inline"}}>{title}</h2>
				{version &&
					<span style={{color: "#6B6B6B", fontSize: 12, position: "absolute", bottom: "calc(.5rem / 2)"}}>&nbsp;{version}</span>
				}	
			</div>

			<div style={{flex: 1, textAlign: "right", marginTop: "-8px"}}>
				<Dropdown>
					<MenuButton
						variant="plain"
						size="sm"
						sx={{ maxWidth: "50px", maxHeight: "50px", borderRadius: "9999999px" }}
					>
						<Avatar sx={{ maxWidth: "50px", maxHeight: "50px" }} />
					</MenuButton>
					<Menu
						placement="bottom-end"
						size="sm"
						sx={{
							zIndex: "99999",
							p: 1,
							gap: 1
						}}
					>
						<MenuItem>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
								}}
							>
								<Avatar sx={{ borderRadius: "50%" }} />
								<Box sx={{ ml: 1.5 }}>
									<Typography level="title-sm" textColor="text.primary">
										{localStorage.getItem("name")}
									</Typography>
									<Typography level="body-xs" textColor="text.tertiary">
										{localStorage.getItem("login")}
									</Typography>
								</Box>
							</Box>
						</MenuItem>
						<ListDivider />
						<Link href="https://docs.alaskar.tech/" target="_blank" underline="none">
							<MenuItem><HelpRoundedIcon />
								Help
							</MenuItem>
						</Link>
						<Link href="/Settings" underline="none">
							<MenuItem>
								<SettingsRoundedIcon />
								Settings
							</MenuItem>
						</Link>
						<ListDivider />
						<MenuItem onClick={logout}>
							<LogoutRoundedIcon />
							Log out
						</MenuItem>
					</Menu>
				</Dropdown>
			</div>
		</div>
	);
}

Header.propTypes = {
	title: PropTypes.string,
	version: PropTypes.string,
	handleLogout: PropTypes.func
};